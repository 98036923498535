import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import { createNewSubCourseQuestion, editSubCourseQuestionById, getQuestionTagsData, getSubCourseQuestionCountData, removeSubCourseQuestionById, searchSubCourseQuestionData, uploadSubCourseQuestionCsvFile } from "./subCourseQuestion.action";
import { IsubCourseQuestionState } from "./subCourseQuestion.model";


export const initialState: IsubCourseQuestionState = {
  SubCourseQuestionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  subCourseCountQuetion: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  createQuestion: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  editById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  removeById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  questionTags: {
    loading: false,
    hasErrors: false,
    message: "",
    tags: [],
  },
  SubCourseQuesionCsvFile: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.createQuestion.message = "";
      state.editById.message = "";
      state.removeById.message = "";
      state.SubCourseQuesionCsvFile.message = "";
    },
  },
  extraReducers: {

    //get subcourseQuestionData
    [searchSubCourseQuestionData.pending.type]: (state) => {
      state.SubCourseQuestionData.loading = true;
    },
    [searchSubCourseQuestionData.fulfilled.type]: (state, action) => {
      state.SubCourseQuestionData.data = action.payload.data;
      state.SubCourseQuestionData.message = action.payload.message;
      state.SubCourseQuestionData.loading = false;
      state.SubCourseQuestionData.hasErrors = false;
    },
    [searchSubCourseQuestionData.rejected.type]: (state, action) => {
      state.SubCourseQuestionData.loading = false;
      state.SubCourseQuestionData.hasErrors = true;
      state.SubCourseQuestionData.message = action.error.message;
    },

    //get question count
    [getSubCourseQuestionCountData.pending.type]: (state) => {
      state.subCourseCountQuetion.loading = true;
    },
    [getSubCourseQuestionCountData.fulfilled.type]: (state, action) => {
      state.subCourseCountQuetion.data = action.payload.data;
      state.subCourseCountQuetion.message = action.payload.message;
      state.subCourseCountQuetion.loading = false;
      state.subCourseCountQuetion.hasErrors = false;
    },
    [getSubCourseQuestionCountData.rejected.type]: (state, action) => {
      state.subCourseCountQuetion.loading = false;
      state.subCourseCountQuetion.hasErrors = true;
      state.subCourseCountQuetion.message = action.error.message;
    },

    // create question
    [createNewSubCourseQuestion.pending.type]: (state) => {
      state.createQuestion.loading = true;
    },
    [createNewSubCourseQuestion.fulfilled.type]: (state, action) => {
      state.createQuestion.message = action.payload.message;
      state.createQuestion.hasErrors = action.payload.error;
      state.createQuestion.loading = false;
      state.createQuestion.hasErrors = false;
    },
    [createNewSubCourseQuestion.rejected.type]: (state, action) => {
      state.createQuestion.loading = false;
      state.createQuestion.hasErrors = true;
      state.createQuestion.message = action.error.message;
    },

    //edit quetion
    [editSubCourseQuestionById.pending.type]: (state) => {
      state.editById.loading = true;
    },
    [editSubCourseQuestionById.fulfilled.type]: (state, action) => {
      state.editById.message = action.payload.message;
      state.editById.loading = false;
      state.editById.hasErrors = false;
    },
    [editSubCourseQuestionById.rejected.type]: (state, action) => {
      state.editById.loading = false;
      state.editById.hasErrors = true;
      state.editById.message = action.error.message;
    },

    //Delete question
    [removeSubCourseQuestionById.pending.type]: (state) => {
      state.removeById.loading = true;
    },
    [removeSubCourseQuestionById.fulfilled.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = false;
      state.removeById.message = action.payload.message;
    },
    [removeSubCourseQuestionById.rejected.type]: (state, action) => {
      state.removeById.loading = false;
      state.removeById.hasErrors = true;
      state.removeById.message = action.error.message;
    },

    //get question tags
    [getQuestionTagsData.pending.type]: (state) => {
      state.questionTags.loading = true;
    },
    [getQuestionTagsData.fulfilled.type]: (state, action) => {
      state.questionTags.tags = action.payload.tags;
      state.questionTags.message = action.payload.message;
      state.questionTags.loading = false;
      state.questionTags.hasErrors = false;
    },
    [getQuestionTagsData.rejected.type]: (state, action) => {
      state.questionTags.loading = false;
      state.questionTags.hasErrors = true;
      state.questionTags.message = action.error.message;
    },

    //upload csv file of question
    [uploadSubCourseQuestionCsvFile.pending.type]: (state) => {
      state.SubCourseQuesionCsvFile.loading = true;
    },
    [uploadSubCourseQuestionCsvFile.fulfilled.type]: (state, action) => {
      state.SubCourseQuesionCsvFile.message = action.payload.message;
      state.SubCourseQuesionCsvFile.hasErrors = action.payload.error;
      state.SubCourseQuesionCsvFile.loading = false;
      state.SubCourseQuesionCsvFile.hasErrors = false;
    },
    [uploadSubCourseQuestionCsvFile.rejected.type]: (state, action) => {
      state.SubCourseQuesionCsvFile.loading = false;
      state.SubCourseQuesionCsvFile.hasErrors = true;
      state.SubCourseQuesionCsvFile.message = action.error.message;
    },

  },
});

// A selector
export const questionSelector = (state: RootState) => state.question;

export const { clearRemoveMessage } = questionSlice.actions;

// The reducer
export default questionSlice.reducer;
