import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
} from "antd";
import React, { useState } from "react";
import { ICreateQuestion } from "src/services/subCourseQuestion/subCourseQuestion.model";
import FloatLabel from "../Form/FloatLabel";
import { Option } from "antd/lib/mentions";
import {
  AnswerType,
  Priority,
  QuestionType,
  TopicType,
} from "src/utils/constants/constant";
import TextEditor from "../TextEditor";
import CustomLabel from "../CustomLabel";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { IQuestionFormAddProps } from "./quetionForm.modal";

export const QuestionForm = (props: IQuestionFormAddProps) => {
  const { onSubmit, selectedSubTopic } = props;
  const { Text } = Typography;
  const [form] = Form.useForm();
  const [editorValue, setEditorValue] = useState("");
  const typeOfQuestion = Form.useWatch("question_type", form);

  const rules = {
    type: [{ required: true, message: "Please Select Type" }],
    questiontype: [{ required: true, message: "Please Select Question Type" }],
    severity: [{ required: true, message: "Please Select severity" }],
    question: [{ required: true, message: "Please Enter question" }],
    duration: [{ required: true, message: "Please Enter Duration" }],
    subTopics: [{ required: false, message: "Please Select subTopic" }],
    topic: [{ required: false, message: "Please Select topic" }],
    marks: [
      { required: true, message: "Please Enter marks" },
      {
        validator: (rule: any, value: number) => {
          if (!value || value <= 100) {
            return Promise.resolve();
          }
          return Promise.reject("Marks must not be greater than 100");
        },
      },
      {
        pattern: new RegExp(/^[0-9]+$/),
        message: "Only numbers are allowed",
      },
    ],
    option: [{ required: true, message: "Please Enter option" }],
    question_tags: [{ required: true, message: "Please Select Question Tags" }],
    answer_type: [{ required: true, message: "Please Select Answer Type" }],
  };

  return (
    <div>
      <Form
        className="drawer-form question-form"
        name="question_create_form"
        form={form}
        initialValues={{
          question_tags: selectedSubTopic?.tags
            ? selectedSubTopic?.tags.map((tag) => tag.name)
            : [],
          mcq_answer: [""],
        }}
        onFinish={(values: ICreateQuestion) => {
          onSubmit(values);
        }}
        layout="vertical"
        autoComplete="off"
        requiredMark={true}
      >
        <Row gutter={10}>
          <Col span={12}>
            <FloatLabel
              label="Topic Type"
              placeholder="Select Topic Type"
              name="topic_type"
            >
              <Form.Item name="topic_type">
                <Select
                  size="large"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                  disabled
                >
                  <Option key="1" value={TopicType.LECTURE}>
                    {TopicType.LECTURE}
                  </Option>
                  <Option key="2" value={TopicType.PROJECT}>
                    {TopicType.PROJECT}
                  </Option>
                  <Option key="3" value={TopicType.VIVA}>
                    {TopicType.VIVA}
                  </Option>
                  <Option key="4" value={TopicType.EXAM_PRACTICAL}>
                    {TopicType.EXAM_PRACTICAL}
                  </Option>
                  <Option key="5" value={TopicType.EXAM_THEORY}>
                    {TopicType.EXAM_THEORY}
                  </Option>
                  <Option key="6" value={TopicType.ASSIGNMENT}>
                    {TopicType.ASSIGNMENT}
                  </Option>
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <FloatLabel
              label="Question Type"
              placeholder="Select Question Type"
              required
              name="question_type"
            >
              <Form.Item name="question_type" rules={rules.questiontype}>
                <Select
                  size="large"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                >
                  <Option key="1" value={QuestionType.MCQ}>
                    {QuestionType.MCQ}
                  </Option>
                  <Option key="2" value={QuestionType.SUBJECTIVE}>
                    {QuestionType.SUBJECTIVE}
                  </Option>
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          {selectedSubTopic &&
            selectedSubTopic.type === TopicType.EXAM_PRACTICAL &&
            typeOfQuestion === QuestionType.SUBJECTIVE && (
              <Col xs={24}>
                <FloatLabel
                  label="Answer Type"
                  placeholder="Select Answer Type"
                  required
                  name="answer_type"
                >
                  <Form.Item name="answer_type" rules={rules.answer_type}>
                    <Select
                      size="large"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (option?.children?.toString() || "")
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                    >
                      <Option key="1" value={AnswerType.URL}>
                        {AnswerType.URL}
                      </Option>
                      <Option key="2" value={AnswerType.UPLOAD_FILE}>
                        {AnswerType.UPLOAD_FILE}
                      </Option>
                    </Select>
                  </Form.Item>
                </FloatLabel>
              </Col>
            )}
          <Col span={12}>
            <FloatLabel
              label="Severity Type"
              placeholder="Select Severity Type"
              required
              name="severity"
            >
              <Form.Item name="severity" rules={rules.severity}>
                <Select
                  size="large"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                >
                  <Option key="1" value={Priority.HIGH}>
                    {Priority.HIGH}
                  </Option>
                  <Option key="2" value={Priority.MEDIUM}>
                    {Priority.MEDIUM}
                  </Option>
                  <Option key="3" value={Priority.LOW}>
                    {Priority.LOW}
                  </Option>
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <FloatLabel label="Marks" placeholder="Marks" name="marks" required>
              <Form.Item name="marks" rules={rules.marks}>
                <InputNumber size="large" min={0} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Question"
              placeholder="Enter Question"
              name="question"
              required
            >
              <Form.Item name="question" rules={rules.question}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col
            span={24}
            className="gx-text-left text-editor-border"
            id="subtopiceditor"
          >
            <FloatLabel
              label="Description"
              placeholder="Enter Description"
              name="description"
            >
              <Form.Item name="description">
                <TextEditor
                  value={editorValue || " "}
                  placeholder=""
                  onChange={setEditorValue}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col xs={24}>
            <FloatLabel
              label="Tags"
              placeholder="Select Tags"
              name="question_tags"
            >
              <Form.Item name="question_tags" rules={rules.question_tags}>
                <Select
                  mode="tags"
                  allowClear
                  showSearch
                  style={{ width: "100%" }}
                  filterOption={(value, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  }
                >
                  {selectedSubTopic?.tags &&
                    selectedSubTopic.tags?.map((tag) => (
                      <Option key={tag?.id?.toString()} value={tag.name}>
                        {tag?.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
        </Row>
        <div style={{ display: "block" }}>
          <hr className="gx-mt-1" />
          {typeOfQuestion === QuestionType.MCQ && (
            <>
              <CustomLabel label="Option" />
              <Form.List name="mcq_answer">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <div key={key}>
                          <Row gutter={24}>
                            <Col sm={16} md={16} lg={16} xs={12}>
                              <FloatLabel
                                label="Option"
                                placeholder="Enter Option Of MCQ"
                                name={["mcq_answer", name, "optaion"]}
                                required
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "optaion"]}
                                  rules={rules.option}
                                >
                                  <Input size="large" />
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                            <Col
                              md={6}
                              lg={6}
                              sm={6}
                              xs={9}
                              className="gx-text-left"
                            >
                              <Text strong style={{ display: "block" }}>
                                Option Is
                              </Text>
                              <FloatLabel
                                label=""
                                placeholder=""
                                name={["mcq_answer", name, "is_right"]}
                              >
                                <Form.Item
                                  {...restField}
                                  name={[name, "is_right"]}
                                >
                                  <Radio.Group>
                                    <Radio value={true}> True </Radio>
                                    <Radio value={false}> False </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </FloatLabel>
                            </Col>
                            <Col className="" lg={2} md={2} sm={2} xs={2}>
                              {key !== 0 ? (
                                <Button
                                  onClick={() => remove(name)}
                                  icon={<MinusOutlined />}
                                ></Button>
                              ) : (
                                <Button
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                ></Button>
                              )}
                            </Col>
                            <Col xs={24} className="gx-d-lg-none">
                              <hr />
                            </Col>
                          </Row>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </Form.List>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};
