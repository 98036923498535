import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISystemTrackerDetails } from "src/services/systemTracker/systemTracker.model";
import SyatemTrackerService from "src/services/systemTracker/systemTracker.service";


export const getSystemTrackerDetails = createAsyncThunk(
    "get-pc-details/getSystemTrackerDetails",
    async (data: ISystemTrackerDetails) => {
        return SyatemTrackerService.getSystemTrackerDetails(data);
    }
);