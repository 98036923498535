import { Col, DatePicker, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchPaymentModeData } from "src/state/paymentMode/paymentMode.action";
import { paymentModeSelector } from "src/state/paymentMode/paymentMode.reducer";
import FloatLabel from "../Form/FloatLabel";
import { IPaymentModeProps } from "./PaymentMode.model";
const { Option } = Select;

const PaymentModeComponent = (props: IPaymentModeProps) => {
  const { lg, md, span, xl, xs, sm, isExpense, status , hidePaymentMode} = props;
  const form = Form.useFormInstance();
  const dispatch = useDispatch<AppDispatch>();
  const paymentModeState = useAppSelector(paymentModeSelector);
  const [chequeFields, setChequeFields] = useState<boolean>(false);
  const [isNEFTorIMPS, setIsNEFTorIMPS] = useState<boolean>(false);
  const [isValue, setIsValue] = useState<boolean>(false);
  const [allFiledShow, setAllFiledShow] = useState<boolean>(false);

  const dateFormat = "DD/MM/YYYY";
  const rules = {
    payment_mode: [{ required: true, message: "Please select payment mode" }
     ],
    cheque_holder_name: [
      { required: true, message: "Please Enter cheque holder name" },
      
      { min: 0, max: 40 }
    ],
    cheque_or_dd_no: [
      { required: true, message: "Please Enter cheque/dd no." },
      { pattern: new RegExp(/^[0-9a-zA-Z]+$/), message: "Only numbers and alphabets are allowed" },
      { min: 0, max: 40 }
    ],
    cheque_or_dd_date: [
      { required: true, message: "Please select cheque/dd date" },

    ],
    bank_name: [{ required: true, message: "Please Enter bank name" },
  
    { min: 0, max: 40 }],
    bank_branch_name: [
      { required: true, message: "Please Enter bank branch name" },
      
    ],
    cheque_status: [{ required: true, message: "Please select cheque status" }],
    transaction_no: [
      { required: true, message: "Please Enter transaction no." },
      { min: 0, max: 40 },
      
      { pattern: new RegExp(/^[0-9a-zA-Z]+$/), message: "Only numbers and alphabets are allowed" },
    ],
    transaction_date: [
      { required: true, message: "Please Enter transaction date" },
    ],
  };

  const paymentModeChange = Form.useWatch("payment_mode", form);
  useEffect(() => {
    if (paymentModeChange) {
      if (paymentModeChange === "CHEQUE" || paymentModeChange === "DD") {
        isExpense === true
          ? form.setFieldValue("cheque_status", status)
          : form.setFieldValue("cheque_status", "TO_BE_COLLECTED");
        setAllFiledShow(true);
        setIsNEFTorIMPS(false);
        setChequeFields(true);
      } else if (paymentModeChange === "NEFT/IMPS") {
        setAllFiledShow(true);
        setChequeFields(true);
        setIsNEFTorIMPS(true);
      } else if (paymentModeChange === "CASH") {
        setAllFiledShow(false);
      } else {
        setAllFiledShow(true);
        setChequeFields(false);
        setIsNEFTorIMPS(false);
        setIsValue(true);
      }
    } else {
      setAllFiledShow(false);
    }
  }, [paymentModeChange]);

  useEffect(() => {
    dispatch(
      searchPaymentModeData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
  }, []);

  return (
    <>
      <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
        <FloatLabel
          label="Select Payment Mode"
          placeholder="Select Payment Mode"
          name="payment_mode"
          required
        >
          <Form.Item name="payment_mode" rules={rules.payment_mode}>
            <Select
              getPopupContainer={(trigger) => trigger.parentNode}
              size="large"
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.children?.toString() || "")
                  .toLowerCase()
                  .includes(input.toLowerCase().trim())
              }
            >
              {isExpense === true
                ? paymentModeState.paymentModeData.data?.rows.map((p) => {
                    if (
                      p.name === "CASH" ||
                      p.name === "ONLINE_PAYMENT" ||
                      p.name === "CHEQUE"
                    ) {
                      return (
                        <Option key={p.id} value={p.name}>
                          {p.name}
                        </Option>
                      );
                    }
                  })
                : paymentModeState.paymentModeData.data?.rows
                ?.filter((paymentMode) => !(hidePaymentMode?.includes(paymentMode?.name)))
                .map((paymentModeDetails) => {
                    return (
                      <Option key={paymentModeDetails.id} value={paymentModeDetails.name}>
                        {paymentModeDetails.name}
                      </Option>
                    );
                  })}
            </Select>
          </Form.Item>
        </FloatLabel>
      </Col>
      {allFiledShow === true && (
        <>
          {chequeFields === true ? (
            <>
              <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                <FloatLabel
                  label="Cheque Holder Name"
                  placeholder="Enter Cheque Holder name"
                  name="cheque_holder_name"
                  required
                >
                  <Form.Item
                    name="cheque_holder_name"
                    rules={rules.cheque_holder_name}
                  >
                    <Input size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                <FloatLabel
                  label="Cheque/DD No"
                  placeholder="Enter Cheque/DD no"
                  name="cheque_or_dd_no"
                  required
                >
                  <Form.Item
                    name="cheque_or_dd_no"
                    rules={rules.cheque_or_dd_no}
                  >
                    <Input size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                <FloatLabel
                  label="Select Cheque/DD date"
                  placeholder="Select Cheque/DD date"
                  name="cheque_or_dd_date"
                >
                  <Form.Item
                    name="cheque_or_dd_date"
                    rules={rules.cheque_or_dd_date}
                  >
                    <DatePicker
                      size="large"
                      placeholder=""
                      style={{ width: "100%" }}
                      format={dateFormat}
                    />
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                <FloatLabel
                  label="Bank Name"
                  placeholder="Enter Bank name"
                  name="bank_name"
                  required
                >
                  <Form.Item name="bank_name" rules={rules.bank_name}>
                    <Input size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </FloatLabel>
              </Col>

              <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                <FloatLabel
                  label="Bank Branch Name"
                  placeholder="Enter Bank Branch name"
                  name="bank_branch_name"
                  required
                >
                  <Form.Item
                    name="bank_branch_name"
                    rules={rules.bank_branch_name}
                  >
                    <Input size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </FloatLabel>
              </Col>

              {isNEFTorIMPS === false && (
                <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                  <FloatLabel
                    label="Select Cheque Status"
                    placeholder="Select Cheque Status"
                    name="cheque_status"
                    required
                  >
                    <Form.Item name="cheque_status" rules={rules.cheque_status}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        allowClear
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.children?.toString() || "")
                            .toLowerCase()
                            .includes(input.toLowerCase().trim())
                        }
                      >
                        {isExpense === true ? (
                          <>
                            <Option value="TO_BE_COLLECTED">
                              Collected
                            </Option>
                            <Option value="TO_BE_DEPOSIT">Deposited</Option>
                            <Option value="TO_BE_CLEARED">Cleared</Option>
                            <Option value="TO_BE_BOUNCE">Bounced</Option>
                            <Option value="TO_BE_CANCELED">
                              Cancelled
                            </Option>
                          </>
                        ) : (
                          <Option value="TO_BE_COLLECTED">
                            Collected
                          </Option>
                        )}
                      </Select>
                    </Form.Item>
                  </FloatLabel>
                </Col>
              )}
            </>
          ) : (
            <>
              {isValue === true && (
                <>
                  <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                    <FloatLabel
                      label="Transaction No"
                      placeholder="Enter Transaction No"
                      name="transaction_no"
                      required
                    >
                      <Form.Item
                        name="transaction_no"
                        rules={rules.transaction_no}
                      >
                        <Input size="large" style={{ width: "100%" }} />
                      </Form.Item>
                    </FloatLabel>
                  </Col>

                  <Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} span={span}>
                    <FloatLabel
                      label="Transaction Date"
                      placeholder="Select Transaction Date"
                      name="transaction_date"
                      
                    >
                      <Form.Item className="transaction-date"
                        name="transaction_date"
                        rules={rules.transaction_date}
                      >
                        <DatePicker
                          size="large"
                          placeholder=""
                          style={{ width: "100%" }}
                          format={dateFormat}
                        />
                      </Form.Item>
                    </FloatLabel>
                  </Col>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PaymentModeComponent;
