import { Button, Card, Col, Row, message, Skeleton, Form } from "antd";
import { useEffect, useState } from "react";
import ContainerHeader from "src/components/ContainerHeader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import {
  getAdmissionRecurringData,
  getInterviewSessionData,
  getPisAdmissionRecurringData,
} from "src/state/students/students.action";

import { searchUserData } from "src/state/users/user.action";
import { searchBranchData } from "src/state/branch/branch.action";
import ReportInterviewSessionField from "./ReportInterviewSessionField";

const InterviewSessionUpdateCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      searchUserData({ noLimit: true, orderBy: "first_name", order: "ASC" })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );
    dispatch(getInterviewSessionData());
    // if (studentState.admissionRecurringData.loading === false) {
    //   dispatch(
    //     getAdmissionRecurringData({
    //       ...searchParams,
    //       noLimit: true,
    //       order: "ASC",
    //       orderBy: "end_date",
    //     })
    //   ).then(() => setLoading(false));
    // }

    dispatch(
      getPisAdmissionRecurringData({
        ...searchParams,
        noLimit: true,
        order: "ASC",
        orderBy: "end_date",
      })
      
    ).then(() => setLoading(false));
  }, []);

  return (
    <div className="rnw-main-content">
      <Skeleton loading={loading} active avatar>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xxl={12}>
            <ContainerHeader title={"Placement Interview Session card"} />
          </Col>
          <Col>
            <Button
              title={"Print"}
              className="float-right print-btn"
              onClick={() => window.print()}
            >
              <i className="fa fa-print"></i>
            </Button>
            <Button onClick={() => navigate("/")} className="back-button">
              {" "}
              <i className="fa fa-arrow-left back-icon"></i>{" "}
              <span className="gx-d-none gx-d-sm-inline-block">Back</span>
            </Button>
          </Col>
        </Row>

        <Card className="gx-mt-3 report-card">
          <ReportInterviewSessionField
            isDrawer={false}
            isEdit={true}
            form={form}
          />
        </Card>
      </Skeleton>
    </div>
  );
};

export default InterviewSessionUpdateCard;
