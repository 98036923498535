import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
  Table,
} from "antd";
import {
  FilterFilled,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import FloatLabel from "src/components/Form/FloatLabel";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { reportSelector } from "src/state/report/report.reducer";
import {
  currencyFormat,
  downloadFile,
  showTooltip,
  trimObject,
} from "src/utils/helperFunction";
import { ColumnName, IFilterData, IFilterStateData } from "./Counsellor.model";
import { packageSelector } from "src/state/package/package.reducer";
import { IPackageRecord, Root } from "../Package/Packages.model";
import { searchPackageData } from "src/state/package/package.action";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchSubCourseData } from "src/state/subCourse/subCourse.action";
import { searchCourseData } from "src/state/course/course.action";
import { courseSelector } from "src/state/course/course.reducer";
import { subcourseSelector } from "src/state/subCourse/subCourse.reducer";
import { ISubCourseDetails } from "src/services/subCourse/subCourse.model";
import { searchCounsellorData } from "src/state/report/report.action";
import {
  ICounsellorDetailsRowData,
  ISubCourseTags,
} from "src/services/report/report.model";
import { tagSelector } from "src/state/tags/tags.reducer";
import { getTags } from "src/state/tags/tags.action";
import { PaginationProps } from "antd/es/pagination";
import { ColumnType, ColumnsType } from "antd/lib/table";
import { ZoneType } from "src/utils/constants/constant";
import { searchAllZoneData } from "src/state/zone/zone.action";
import { userSelector } from "src/state/users/user.reducer";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { IBranchDetails } from "src/services/branch/branch.model";
import { PackageBranch } from "src/services/package/package.model";
import { IBranchData } from "../Department/Department.model";
import MoreButtonShow from "src/components/MoreButton";
import TableExpandableComponent from "src/components/DataTableExpandable";
import CommonFilter from "src/components/CommonFilter";

const Counsellor = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  let location = useLocation();
  const pageSizeOptions = Object.freeze(["10", "20", "50", "100"]);
  const [form] = Form.useForm();
  const reportState = useAppSelector(reportSelector);
  const tagState = useAppSelector(tagSelector);
  const branchState = useAppSelector(branchSelector);
  const zoneState = useAppSelector(zoneSelector);
  const subCourseState = useAppSelector(subcourseSelector);
  const packageState = useAppSelector(packageSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState<number>(0);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState({});
  const [dynamicPackage, setDynamicPackage] = useState<IPackageRecord[]>([]);
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const [dynamicBranch, setDynamicBranch] = useState<IBranchDetails[]>([]);

  const [currentPage, setCurrentPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  const [pageSize, setPagesize] = useState<number>(20);
  const { Option } = Select;
  const initialValueOfFilterState = {
    eligibilityFilter: "",
    tagsFilter: [],
    packageFilter: null,
    subCourseFilter: null,
    zoneIds: [],
    branchIds: [],
  };
  const [filterState, setFilterState] = useState<IFilterStateData>(
    initialValueOfFilterState
  );
  const filterZoneIds = form.getFieldValue("zone_ids");

  const filterDataConvertFromSearchParam = () => {
    const filterData: IFilterStateData = {
      eligibilityFilter: "",
      tagsFilter: [],
      packageFilter: null,
      subCourseFilter: null,
      zoneIds: [],
      branchIds: [],
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        setSearchParams("");
      } else if (key === "tag_ids") {
        if (value !== "") {
          filterData.tagsFilter = Array.from(value.split(","), Number);
        }
      } else if (key === "eligibility") {
        if (value !== "") {
          filterData.eligibilityFilter = value?.trim();
        }
      } else if (key === "subcourse_id") {
        if (value !== "") {
          filterData.subCourseFilter = +value;
        }
      } else if (key === "package_id") {
        if (value !== "") {
          filterData.packageFilter = +value;
        }
      } else if (key === "zone_ids") {
        if (value !== "") {
          filterData.zoneIds = Array.from(value.split(","), Number);
        }
      } else if (key === "branch_ids") {
        if (value !== "") {
          filterData.branchIds = Array.from(value.split(","), Number);
        }
      }
    }

    setFilterState(filterData);
  };

  useEffect(() => {
    dispatch(searchCounsellorData())?.then((res) => {
      if (res?.payload) {
        setLoading(false);
      }
    });

    filterDataConvertFromSearchParam();
  }, []);

  const checkPackageInZoneIsExist = (data: ICounsellorDetailsRowData) => {
    const zoneIds: number[] = filterState?.zoneIds;

    const allZoneIds =
      zoneState.allZonesData.data.rows
        ?.filter(
          (data) =>
            data?.parent_id?.id && zoneIds?.includes(data?.parent_id?.id)
        )
        ?.map((data) => data?.id) || [];
    allZoneIds?.push(...zoneIds);

    const isExistData = data?.package_branches?.some((branchData) =>
      branchData?.branches?.some((branch) =>
        allZoneIds?.includes(branch.zone_id)
      )
    );

    return isExistData;
  };

  const checkPackageInBranchIsExist = (data: ICounsellorDetailsRowData) => {
    const branchIds = filterState?.branchIds || [];

    const isExist = branchIds?.some((branchId) =>
      data?.package_branches?.some(
        (branchData) => branchData?.branch_id === branchId
      )
    );

    return isExist;
  };

  const checkSubCourseInBranchIsExist = (data: ICounsellorDetailsRowData) => {
    const branchIds = filterState?.branchIds || [];
    const isExist = branchIds?.some((branchId) =>
      data?.course?.branch_courses?.some(
        (branchData) => branchData?.branch_id === branchId
      )
    );
    return isExist;
  };

  const checkSubCourseInZoneIsExist = (data: ICounsellorDetailsRowData) => {
    const zoneIds: number[] = filterState?.zoneIds;

    const allIds =
      zoneState.allZonesData.data.rows
        ?.filter(
          (data) =>
            data?.parent_id?.id && zoneIds?.includes(data?.parent_id?.id)
        )
        ?.map((data) => data?.id) || [];
    allIds?.push(...zoneIds);
    const isExistData = data?.course?.branch_courses?.some((branchData) =>
      allIds?.includes(branchData?.branch?.zone_id || 0)
    );

    return isExistData;
  };

  const finalCounsellorData = reportState?.counsellorDetailsData?.data
    ?.map((row) => ({ ...row, key: `${row.type}_${row.id}` }))
    ?.filter((row) =>
      filterState?.zoneIds?.length > 0 && row?.type === ColumnName.SUB_COURSE
        ? checkSubCourseInZoneIsExist(row)
        : true
    )
    ?.filter((row) =>
      filterState?.branchIds?.length > 0 && row?.type === ColumnName.SUB_COURSE
        ? checkSubCourseInBranchIsExist(row)
        : true
    )
    ?.filter((row) =>
      filterState?.zoneIds?.length > 0 && row?.type === ColumnName.PACKAGE
        ? checkPackageInZoneIsExist(row)
        : true
    )
    ?.filter((row) =>
      filterState?.branchIds?.length > 0 && row?.type === ColumnName.PACKAGE
        ? checkPackageInBranchIsExist(row)
        : true
    )
    ?.filter((row) =>
      filterState.eligibilityFilter
        ? row?.eligibility &&
          row?.eligibility
            ?.toLowerCase()
            ?.trim()
            ?.includes(filterState?.eligibilityFilter?.toLowerCase()?.trim())
        : true
    )
    ?.filter((row) =>
      filterState?.tagsFilter?.length
        ? filterState.tagsFilter.some((tagId) =>
            row.type === ColumnName.SUB_COURSE
              ? row?.subcourseTags?.some(
                  (subCourseTagDetails) => subCourseTagDetails?.tag_id === tagId
                )
              : row?.packageTags?.some(
                  (packageTagDetails) => packageTagDetails?.tag_id === tagId
                )
          )
        : true
    )
    ?.filter((row) =>
      filterState?.packageFilter
        ? row?.type === ColumnName.PACKAGE
          ? filterState?.packageFilter === row.id
          : false
        : row
    )
    ?.filter((row) =>
      filterState?.subCourseFilter
        ? row?.type === ColumnName.SUB_COURSE
          ? filterState?.subCourseFilter === row.id
          : row?.package_subcourses?.some(
              (subCourseDetails) =>
                subCourseDetails?.subcourse_id === filterState?.subCourseFilter
            )
        : row
    );

  useEffect(() => {
    // Search Tags List
    dispatch(getTags());

    dispatch(
      searchAllZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
      })
    );

    dispatch(
      searchPackageData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isAllBranch: true,
      })
    );

    dispatch(
      searchCourseData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );

    dispatch(
      searchSubCourseData({ noLimit: true, orderBy: "name", order: "ASC" })
    );
    const keysToSplit = ["tag_ids", "zone_ids", "branch_ids"];
    const data = { ...setFormValues };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (keysToSplit?.includes(key)) {
        Object.assign(data, {
          [key]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }

    setFormValues(data);

    setPagesize(Number(searchParams.get("take")) || 20);
  }, []);

  // package on branch
  useEffect(() => {
    if (
      branchState?.branchesData?.data?.rows &&
      packageState?.packageData?.data?.rows
    ) {
      const branchIds = branchState?.branchesData?.data?.rows?.map(
        (branchDetails) => +branchDetails.id
      );
      const packagesData = packageState?.packageData?.data?.rows;

      const packagesOnBranch =
        packagesData.filter((packageDetails) => {
          return (
            packageDetails &&
            packageDetails?.package_branches?.find((packageBranchDetails) =>
              branchIds?.includes(packageBranchDetails?.branch_id)
            )
          );
        }) || [];
      setDynamicPackage(packagesOnBranch as IPackageRecord[]);
    }
  }, [
    packageState?.packageData?.data?.rows,
    branchState?.branchesData?.data?.rows,
  ]);

  useEffect(() => {
    if (new URLSearchParams(searchParams)?.toString()?.length === 0) {
      setFilterState(initialValueOfFilterState);
    }
  }, [searchParams]);

  const commonPopover = (
    dataArray: string[],
    title: string,
    showItemCount = 3,
    department?: string
  ) => {
    const items = dataArray?.slice(0, showItemCount)?.join(", ");
    return (
      <>
        <div className="roles-details gx-d-flex">
          <span className="gx-mb-1">{`${items}`}</span>
          {dataArray?.length > showItemCount && (
            <>
              <div className="gx-ml-2">
                <Popover
                  getPopupContainer={(parent) =>
                    parent.parentElement as HTMLElement
                  }
                  content={
                    <p className="gx-mb-1 role-modal-details">
                      {dataArray?.map((tagName: string) => {
                        return <p className={`gx-mb-1`}>{tagName}</p>;
                      })}
                    </p>
                  }
                  placement="bottomLeft"
                  title={title}
                  trigger="click"
                >
                  <Button type="primary">More</Button>
                </Popover>
              </div>
            </>
          )}
        </div>
        {department && (
          <small className="faculty-name">{showTooltip(department, 30)}</small>
        )}
      </>
    );
  };

  const columns: ColumnType<object>[] = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <>{(currentPage - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "Sub-Course/Package",
      width: "30%",
      render: (record) => {
        const concatName =
          record?.type === ColumnName.PACKAGE
            ? `P-${record.name}`
            : `S-${record.name}`;
        const maxLength = 40;
        return <span>{showTooltip(concatName, maxLength)}</span>;
      },
    },
    {
      title: "Branch Code",
      width: "30%",
      render: (record) => {
        const branchCodeList =
          record?.type === ColumnName.PACKAGE
            ? record?.package_branches?.flatMap(
                (branchData: PackageBranch) => branchData?.branch?.code
              )
            : record?.course?.branch_courses?.flatMap(
                (branchData: IBranchData) => branchData?.branch?.code
              );

        return (
          <>
            {branchCodeList?.length > 0 &&
              commonPopover(
                branchCodeList,
                "Branch Code",
                1,
                record?.type === ColumnName.PACKAGE
                  ? record.department.name
                  : null
              )}
          </>
        );
      },
    },
    {
      title: "Fees/Installment",
      render: (record) => {
        return (
          <>
            <span>{currencyFormat(record?.total)}</span>
            <br />
            <span>{record?.installment}</span>
          </>
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
    },
    {
      title: "Eligibility",
      dataIndex: "eligibility",
      width: "25%",
      render: (record) => {
        const maxLength = 20;
        const truncatedRemarkValue =
          record?.length > maxLength
            ? `${record?.substring(0, maxLength)}...`
            : record;

        return (
          <div className="counsellor-more-btn roles-details gx-d-flex">
            {truncatedRemarkValue ? (
              <>
                <>
                  <p className="gx-mb-1">{truncatedRemarkValue}</p>
                </>
                {record?.length > maxLength && (
                  <div className="gx-ml-2">
                    <Popover
                      content={
                        <p className={`gx-mb-1 gx-p-2 hold-popover`}>
                          {record}
                        </p>
                      }
                      placement="bottomRight"
                      title="Eligibility"
                      trigger="click"
                      getPopupContainer={(parent) =>
                        parent.parentElement as HTMLElement
                      }
                    >
                      <Button type="primary">More</Button>
                    </Popover>
                  </div>
                )}
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Tag",
      dataIndex: "",
      width: "25%",
      render: (tags) => {
        let tagsData = [];
        if (tags?.subcourseTags?.length > 0) {
          tagsData = tags?.subcourseTags?.map((tagDetails: ISubCourseTags) =>
            tagDetails?.tag?.name?.trim()
          );
        } else if (tags?.packageTags?.length > 0) {
          tagsData = tags?.packageTags?.map((tagDetails: ISubCourseTags) =>
            tagDetails?.tag?.name?.trim()
          );
        }

        return <>{tagsData?.length > 0 && commonPopover(tagsData, "Tags")}</>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (record) => {
        return record ? (
          <MoreButtonShow
            text={record}
            titleText="Description"
            prefixTitleShow={false}
          />
        ) : (
          ""
        );
      },
    },
    {
      title: "Brochure link",
      dataIndex: "brochure_link",
      align: "center",
      width: "10%",
      render: (brochureLink) => {
        return (
          <>
            {brochureLink && (
              <Button
                icon={<FileTextOutlined />}
                size="small"
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure want to download the brochure?",
                    okText: "Yes",
                    cancelText: "No",
                    icon: <DownloadOutlined />,
                    onOk() {
                      downloadFile(brochureLink);
                    },
                  });
                }}
              />
            )}
          </>
        );
      },
    },
  ];

  const expandedRowRender = (record: Root) => {
    const columns: ColumnsType<IPackageRecord> = [
      {
        title: "SubCourse Name",
        dataIndex: "subcourse",
        render: (record) => {
          return <>{record?.name}</>;
        },
      },
      {
        title: "Fees",
        dataIndex: "subcourse",
        render: (record) => record?.total,
      },
    ];

    return (
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={(record?.package_subcourses as IPackageRecord[]) || []}
        pagination={false}
      />
    );
  };

  const onFinishFilter = (values: IFilterData) => {
    const filterData: IFilterStateData = {
      eligibilityFilter:
        (values.eligibility && values.eligibility?.trim()) || "",
      tagsFilter: (values.tag_ids && Array.from(values.tag_ids, Number)) || [],
      packageFilter: values.package_id ? +values.package_id : null,
      subCourseFilter: values.subcourse_id ? +values.subcourse_id : null,
      zoneIds: (values.zone_ids && Array.from(values.zone_ids, Number)) || [],
      branchIds:
        (values.branch_ids && Array.from(values.branch_ids, Number)) || [],
    };

    const queryString = Object.entries(trimObject(values))
      .filter(([, values]) => values !== undefined && values !== "")
      ?.filter(([, values]) =>
        Array.isArray(values) ? values?.length > 0 : values
      )
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");

    Promise.all([
      setFilterModalOpen(false),
      setSearchParams(queryString),
      setFilterState(filterData),
    ]);
  };

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const onReset = () => {
    form.resetFields();
    Promise.all([
      setCount(0),
      setFormValues(" "),
      setFilterState(initialValueOfFilterState),
      setSearchParams({}),
    ]);
  };

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  useEffect(() => {
    if (searchParams.get("skip") && searchParams.get("take")) {
      setCurrentPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [reportState.counsellorDetailsData.data]);

  const goToPageRecord = (pageSize?: number, skip?: number) => {
    navigate({
      pathname: location.pathname,
      search: `?${createSearchParams([
        ["take", `${pageSize}`],
        ["skip", `${skip ? skip : 0}`],
      ])}`,
    });
  };

  const searchWithPageRecord = (pageSize?: number, skip?: number) => {
    const sp = new URLSearchParams(searchParams.toString());
    sp.set("take", `${pageSize}`);
    sp.set("skip", `${skip ? skip : 0}`);
    setSearchParams(sp.toString());
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    const table = document.querySelector(".table-bg");
    if (table) {
      table.scrollTo(0, 0); // Scroll to top of the table
    }
    setCurrentPage(((page - 1) * pageSize) / pageSize + 1);
    if (location.search) {
      searchWithPageRecord(pageSize, (page - 1) * pageSize);
    } else {
      goToPageRecord(pageSize, (page - 1) * pageSize);
    }
  };

  const onShowSizeChange = (pageSize: number, updatedPageSize: number) => {
    setPagesize(updatedPageSize);
    if (location.search) {
      searchWithPageRecord(pageSize);
    } else {
      goToPageRecord(pageSize);
    }
  };

  const onZoneChangeHandler = async (filterZoneIds: string[]) => {
    const zone_ids = filterZoneIds?.map((zoneId) => +zoneId) || [];
    const branchData =
      (await branchState.branchesData.data.rows.filter((branch) =>
        zone_ids.length > 0
          ? zone_ids.includes(branch.zone?.parent_id[0].id)
          : branch
      )) || [];

    setDynamicBranch(branchData);
  };

  return (
    <>
      <div className="rnw-main-content counsellor-page">
        <Skeleton active loading={loading} avatar>
          <Row
            align="middle"
            justify="space-between"
            gutter={24}
            className="mb-20  gx-flex-wrap"
          >
            <Col xs={6} md={4} xl={4} className="counsellor-title">
              <ContainerHeader title="Counsellor" />
            </Col>
            <Col lg={12} className=" text-align-right">
              <Button
                icon={<FilterFilled />}
                onClick={() => setFilterModalOpen(true)}
              >
                Filter
                <span>
                  <Badge count={count}></Badge>
                </span>
              </Button>
            </Col>
          </Row>

          <>
            <div className="filter" style={{ height: "auto" }}>
              <Drawer
                className="filter-drawer"
                title="Counsellor Filter"
                width="1000"
                visible={filterModalOpen}
                onClose={() => {
                  setFilterModalOpen(false);
                }}
                footer={[
                  <div className="gx-d-flex gx-justify-content-center">
                    <Button
                      className="cancel-filter gx-mr-0"
                      key="back"
                      onClick={onCancel}
                    >
                      <span className="gx-d-none gx-d-sm-block">Cancel</span>
                      <i className="fa fa-close gx-d-sm-none"></i>
                    </Button>
                    <Button
                      className="btn-apply-filter gx-mx-2"
                      key="submit"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      form="myForm"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className="reset-filter"
                      type="default"
                      htmlType="reset"
                      form="myForm"
                      onClick={onReset}
                    >
                      <span className="gx-d-none gx-d-sm-block">Reset</span>
                      <i className="fa fa-refresh gx-d-sm-none"></i>
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  id="myForm"
                  onFinish={onFinishFilter}
                  form={form}
                  onReset={onReset}
                  initialValues={formValues}
                >
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FloatLabel
                        label="Select Zone"
                        placeholder="Select Zone"
                        name="zone_ids"
                      >
                        <Form.Item name="zone_ids">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            showSearch
                            showArrow
                            mode="multiple"
                            size="large"
                            filterOption={(value, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                            onChange={(value: string[]) => {
                              form.setFieldValue("branch_ids", undefined);
                              onZoneChangeHandler(value);
                            }}
                            onClear={() =>
                              form.setFieldValue("zone_ids", undefined)
                            }
                          >
                            {zoneState.allZonesData.data.rows
                              .filter((z) => z.status === true)
                              .filter((z) => z.type === ZoneType.PUBLIC)
                              .map((z) => (
                                <Option value={z.id?.toString()}>
                                  {z.code + "-" + z.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <FloatLabel
                        label="Select Branch Code"
                        placeholder="Select Branch Code"
                        name="branch_ids"
                      >
                        <Form.Item name="branch_ids">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            className="dash_input"
                            showSearch
                            showArrow
                            mode="multiple"
                            size="large"
                            filterOption={(value, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                          >
                            {(filterZoneIds
                              ? dynamicBranch
                              : branchState.branchesData.data.rows || []
                            )
                              .filter((branch) => branch.status === true)
                              .map((branch) => (
                                <Option value={branch.id?.toString()}>
                                  {branch.code}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Tag"
                        placeholder="Select Tag"
                        name="tag_ids"
                      >
                        <Form.Item name="tag_ids">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            size="large"
                            allowClear
                            showArrow
                            mode="multiple"
                            filterOption={(input, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase().trim())
                            }
                          >
                            {tagState?.getTag?.data?.map((tag) => (
                              <Option value={tag.id?.toString()}>
                                {tag.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <FloatLabel
                        label="Eligibility"
                        placeholder="Enter eligibility"
                        name="eligibility"
                      >
                        <Form.Item name="eligibility">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <FloatLabel
                        label="Package"
                        placeholder="Select Package"
                        name="package_id"
                      >
                        <Form.Item name="package_id">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {dynamicPackage?.map((packageDetails) => (
                              <Option
                                value={
                                  packageDetails?.id &&
                                  packageDetails?.id.toString()
                                }
                              >
                                {packageDetails.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>

                    <Col xs={24}>
                      <FloatLabel
                        label="Sub Course"
                        placeholder="Select Sub Course"
                        name="subcourse_id"
                      >
                        <Form.Item name="subcourse_id">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            size="large"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase().trim())
                            }
                          >
                            {subCourseState.searchData?.data?.rows?.map(
                              (subCourse: ISubCourseDetails) => (
                                <Option value={subCourse.id.toString()}>
                                  {subCourse.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </div>
          </>
          <Card className="rnw-card zone gx-mb-0">
            <TableExpandableComponent
              dataSource={finalCounsellorData || []}
              columns={columns}
              pagination={{
                current: currentPage,
                defaultPageSize: pageSize,
                pageSizeOptions: [...pageSizeOptions],
                showSizeChanger: true,
                showTotal: (total: number, range: [number, number]) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: onChange,
                onShowSizeChange: onShowSizeChange,
              }}
              loading={loading}
              isexpandable={{
                rowExpandable: (record: { type: string }) => {
                  return record.type === ColumnName.PACKAGE;
                },
              }}
              expanrowData={expandedRowRender}
            />
          </Card>
        </Skeleton>
      </div>
    </>
  );
};

export default Counsellor;
