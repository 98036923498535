import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import { PlusOutlined, FilterFilled, EyeOutlined } from "@ant-design/icons";
import { AppDispatch } from "src/state/app.model";
import {
  currencyFormat,
  dateFormate,
  openInNewTab,
  trimObject,
} from "src/utils/helperFunction";
import FloatLabel from "src/components/Form/FloatLabel";
import MoreButtonShow from "src/components/MoreButton";
import { IPenaltyFilter, IStudentDetails } from "./PenaltyReceipt.model";
import {
  Common,
  DEFAULT_DATE_FORMAT,
  PayementModeType,
  ZoneType,
} from "src/utils/constants/constant";
import { Can } from "src/ability/can";
import DrawerComponent from "src/components/Drawer";
import AddPenalty from "./AddPenalty";
import {
  createPenalty,
  searchPenaltyData,
} from "src/state/admission/admission.action";
import { useAppSelector } from "src/state/app.hooks";
import {
  admissionSelector,
  clearRemoveMessage,
} from "src/state/admission/admission.reducer";
import { IAddPenaltyFormValues } from "./AddPenalty/AddPenalty.model";
import StudentMobileNumber from "src/components/StudentMobileNumber";
import StudentProfileModel from "src/components/StudentProfileModel";
import TableExpandableComponent from "src/components/DataTableExpandable";
import { searchBranchData } from "src/state/branch/branch.action";
import { branchSelector } from "src/state/branch/branch.reducer";
import { IPenalty } from "src/services/admission/admission.model";
import moment from "moment";
import { userSelector } from "src/state/users/user.reducer";

const Penalty = () => {
  const dispatch = useDispatch<AppDispatch>();
  const admissionState = useAppSelector(admissionSelector);
  const branchState = useAppSelector(branchSelector);
  const { Option } = Select;
  const [searchParams, setSearchParams] = useSearchParams({});
  const [penaltyForm] = Form.useForm();
  const [page, setPage] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [studentDetailsModelOpen, setStudentDetailsModelOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [isPenaltyDrawerOpen, setIsPenaltyDrawerOpen] = useState(false);
  const [selectedGRIDStudentDetails, setSelectedGRIDStudentDetails] =
    useState<IStudentDetails>();
  const [admissionId, setAdmissionId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [form] = Form.useForm();
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const rules = {
    gr_id: [
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
    pay_amount: [
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
  };

  useEffect(() => {
    dispatch(searchPenaltyData(dataConvertFromSearchParm()));
    setLoading(false);
  }, [searchParams]);

  const dataConvertFromSearchParm = () => {
    const data = {
      ...setFormValues,
      transaction_date: "",
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (key === "transaction_date") {
        Object.assign(data, {
          ["transaction_date"]: moment(value),
        });
      } else if (key === "selectedBranchIds") {
        Object.assign(data, {
          ["selectedBranchIds"]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    return data;
  };

  useEffect(() => {
    const data: {
      transaction_date?: string | Date | undefined;
    } = {
      ...dataConvertFromSearchParm(),
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (key === "") {
        Object.assign(data, {
          ["transaction_date"]: moment(value),
        });
      } else if (key === "selectedBranchIds") {
        Object.assign(data, {
          ["selectedBranchIds"]: value.split(","),
        });
      }
    }
    setFormValues(data);
  }, [searchParams]);

  useEffect(() => {
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );
  }, []);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  useEffect(() => {
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [admissionState?.penaltyData?.data]);

  useEffect(() => {
    if (admissionState.createPenalty.message) {
      if (admissionState.createPenalty.hasErrors) {
        message.error(admissionState.createPenalty.message);
      } else {
        message.success(admissionState.createPenalty.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [admissionState.createPenalty.message]);

  const PenaltyDetailsColumn: ColumnsType<IPenalty> = [
    {
      title: "No.",
      dataIndex: "id",
      render: (text, record, index) => (
        <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "GR ID",
      dataIndex: ["admission"],
      className: "admission.gr_id",
      render: (record) => {
        return (
          <div
            className="gx-text-danger"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedGRIDStudentDetails(record);
              setStudentDetailsModelOpen(true);
            }}
          >
            {record.gr_id}
          </div>
        );
      },
    },
    {
      title: "Branch Code",
      dataIndex: ["branch"],
      width: "10%",
      align: "center",
      render: (record) => {
        return <>{record?.code}</>;
      },
    },
    {
      title: "Student Name",
      dataIndex: ["admission"],
      key: "student_name",
      width: "30%",
      render: (record) => {
        return (
          <>
            <span>{record?.first_name + " " + record?.last_name}</span>
            <StudentMobileNumber
              studentMobileNumber={String(record?.mobile_no)}
            />
          </>
        );
      },
    },
    {
      title: "Other Charges Amount",
      dataIndex: ["invoice"],
      sorter: true,
      render: (record) => {
        return <>{currencyFormat(record.payAmount)}</>;
      },
    },
    {
      title: "Payment Mode",
      dataIndex: ["invoice"],
      render: (record) => {
        return <>{record.payment_mode}</>;
      },
    },
    {
      title: "Receipt No",
      dataIndex: ["invoice"],
      render: (record) => {
        return <>{record.invoiceNo}</>;
      },
    },
    {
      title: "GST",
      dataIndex: ["invoice"],
      render: (record) => {
        if (record.isgst == true) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    {
      title: "Reason",
      dataIndex: ["invoice"],
      render: (record) => {
        return record.remarks ? (
          <MoreButtonShow
            text={record.remarks}
            titleText="Reason"
            prefixTitleShow={false}
          />
        ) : (
          ""
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (data) => {
        return (
          <>
            <Tooltip placement="top" title={"View Receipt"}>
              <a
                onClick={() => {
                  openInNewTab(`/penalty_receipt/${data?.invoice?.id}`);
                }}
              >
                <EyeOutlined className="gx-px-2" />
              </a>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setFormValues({});
    setSearchParams({});
    penaltyForm.resetFields();
    setCount(0);
  };

  const onFinish = (values: IPenaltyFilter) => {
    Object.fromEntries(new URLSearchParams(trimObject(searchParams)));
    let queryString = Object.entries(trimObject(values))
      .filter(
        ([, values]) =>
          values !== undefined &&
          values !== null &&
          values !== "" &&
          (Array.isArray(values) ? values.length > 0 : values)
      )
      .map(([key, values]) => key + "=" + encodeURIComponent(values as string))
      .join("&");

    setSearchParams(queryString);
    setFilterModalOpen(false);
  };

  const createPenaltyForm = (values: IAddPenaltyFormValues) => {
    values.isgst = values.isgst ? values.isgst : false;
    values.admission_id = admissionId;
    values.branch_id = branchId;
    dispatch(createPenalty(values))?.then((res) => {
      if (res.payload) {
        setIsPenaltyDrawerOpen(false);
        dispatch(searchPenaltyData(searchParams));
      }
    });
    setIsPenaltyDrawerOpen(false);
  };

  const expandedRowRender = (record: IPenalty) => {
    const columns: ColumnsType<IPenalty> = [
      {
        title: "Transaction No",
        dataIndex: ["invoice"],
        width: "10%",
        render: (record) => {
          return (
            <>
              <Tooltip placement="topLeft" title={record?.transaction_no}>
                <span>
                  {record?.transaction_no?.length > 10
                    ? `${record?.transaction_no
                        ?.replace(/\n/g, " ")
                        .slice(0, 50)}...`
                    : record?.transaction_no?.replace(/\n/g, " ")}
                </span>
              </Tooltip>
            </>
          );
        },
      },
      {
        title: "Transaction Date",
        dataIndex: ["invoice"],
        width: "10%",
        render: (record) => {
          return <>{dateFormate(record?.transaction_date)}</>;
        },
      },
    ];

    const newRecord = [];
    newRecord.push(record);
    return (
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={newRecord}
        pagination={false}
      />
    );
  };

  return (
    <div className="rnw-main-content">
      <Skeleton loading={loading} active avatar>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xxl={12}>
            <ContainerHeader title="Other Charges" />
          </Col>

          <Col xxl={12} className="text-align-right">
            <Button
              icon={<FilterFilled />}
              onClick={() => setFilterModalOpen(true)}
            >
              Filter
              <span>
                <Badge count={count}></Badge>
              </span>
            </Button>
            {/* Change Permission 1111 */}
            <Can
              I={Common.Actions.CAN_ADD}
              a={Common.Modules.ADMISSION.ADMISSION_PENALTY}
            >
              <Button
                type="primary"
                onClick={() => {
                  setIsPenaltyDrawerOpen(true);
                }}
                icon={<PlusOutlined />}
              >
                <span className="gx-d-none gx-d-sm-inline-block gx-ml-1">
                  Other Charges
                </span>
              </Button>
            </Can>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div className="filter" style={{ height: "auto" }}>
              <Drawer
                className="filter-drawer"
                title="Other Charges Filter"
                width="1000"
                visible={filterModalOpen}
                onClose={() => {
                  setFilterModalOpen(false);
                }}
                footer={[
                  <div className="gx-d-flex gx-justify-content-center">
                    <Button
                      className="cancel-filter gx-mr-0"
                      key="back"
                      onClick={onCancel}
                    >
                      <span className="gx-d-none gx-d-sm-block">Cancel</span>
                      <i className="fa fa-close gx-d-sm-none"></i>
                    </Button>
                    <Button
                      className="btn-apply-filter gx-mx-2"
                      key="submit"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      form="penaltyForm"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className="reset-filter"
                      type="default"
                      htmlType="reset"
                      form="penaltyForm"
                      onClick={() => onReset()}
                    >
                      <span className="gx-d-none gx-d-sm-block">Reset</span>
                      <i className="fa fa-refresh gx-d-sm-none"></i>
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  id="penaltyForm"
                  onReset={onReset}
                  onFinish={onFinish}
                  form={penaltyForm}
                  initialValues={formValues}
                >
                  <Row gutter={24}>
                    {currentUserZone?.type !== ZoneType.PRIVATE && (
                      <Col xs={24}>
                        <FloatLabel
                          label="Branch Code"
                          placeholder="Select Branch Code"
                          name="selectedBranchIds"
                        >
                          <Form.Item name="selectedBranchIds">
                            <Select
                              allowClear
                              showArrow
                              showSearch
                              mode="multiple"
                              size="large"
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                            >
                              {branchState.branchesData.data.rows.map(
                                (branch) => (
                                  <Option value={branch.id.toString()}>
                                    {branch.code}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    )}
                    <Col xs={24}>
                      <FloatLabel
                        label="GR ID"
                        placeholder="Enter GR ID"
                        name="gr_id"
                      >
                        <Form.Item name="gr_id" rules={rules.gr_id}>
                          <Input size="large" maxLength={9} />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Other Charges Amount"
                        placeholder="Other Charges Amount"
                        name="pay_amount"
                      >
                        <Form.Item name="pay_amount" rules={rules.pay_amount}>
                          <InputNumber
                            type="number"
                            maxLength={9}
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24} className="gx-pr-md-0 gx-pl-lg-3">
                      <FloatLabel
                        label="Payment Mode"
                        placeholder="Payment Mode"
                        name="payment_mode"
                      >
                        <Form.Item name="payment_mode">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            size="large"
                            showSearch
                            allowClear
                            filterOption={(input, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(input.toLowerCase().trim())
                            }
                          >
                            {Object.keys(PayementModeType)?.map(
                              (paymentMode) => (
                                <Option value={paymentMode}>
                                  {paymentMode}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Transaction No"
                        placeholder="Enter Transaction No"
                        name="transaction_no"
                      >
                        <Form.Item name="transaction_no">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24} className="drawer-input-picker">
                      <FloatLabel
                        label="Select Transaction date"
                        placeholder="Select Transaction date"
                        name="transaction_date"
                      >
                        <Form.Item name="transaction_date">
                          <DatePicker
                            getPopupContainer={(trigger) => trigger}
                            size="large"
                            placeholder=""
                            style={{ width: "100%" }}
                            format={DEFAULT_DATE_FORMAT}
                          />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel label="GST" placeholder="GST" name="isgst">
                        <Form.Item name="isgst">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            size="large"
                            allowClear
                          >
                            <Option value="true">YES</Option>
                            <Option value="false">NO</Option>
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </div>

            <div className="filter">
              {isPenaltyDrawerOpen && (
                <DrawerComponent
                  title={"Create Other Charges"}
                  onClose={() => {
                    setIsPenaltyDrawerOpen(false);
                  }}
                  className="assign-batch"
                  size="large"
                  visible={isPenaltyDrawerOpen}
                  footer={true}
                  label={"Submit"}
                >
                  <AddPenalty
                    onSubmit={createPenaltyForm}
                    setAdmissionId={setAdmissionId}
                    setBranchId={setBranchId}
                  />
                </DrawerComponent>
              )}
            </div>

            <Card className="rnw-card table-card gx-mb-0 expense-table">
              <TableExpandableComponent
                columns={PenaltyDetailsColumn}
                dataSource={admissionState?.penaltyData?.data?.rows || []}
                loading={loading}
                meta={admissionState?.penaltyData?.data?.meta}
                isexpandable={{
                  rowExpandable: (record: IPenalty) => {
                    return record?.invoice?.payment_mode !== "CASH";
                  },
                }}
                expanrowData={expandedRowRender}
              />
            </Card>
          </Col>
        </Row>

        {studentDetailsModelOpen && selectedGRIDStudentDetails && (
          <StudentProfileModel
            openModel={studentDetailsModelOpen}
            setOpenModel={(data) => setStudentDetailsModelOpen(data)}
            modelData={{
              ...selectedGRIDStudentDetails,
              profilePhoto:
                selectedGRIDStudentDetails?.admission_documents[0]?.photos,
              studentName: `${selectedGRIDStudentDetails?.first_name}  
              ${selectedGRIDStudentDetails?.middle_name}  
              ${selectedGRIDStudentDetails?.last_name}`,
              batch_name: selectedGRIDStudentDetails?.batch?.name,
              admission_id: selectedGRIDStudentDetails?.id,
            }}
          />
        )}
      </Skeleton>
    </div>
  );
};

export default Penalty;
