import React, { useEffect, useState } from "react";
import {
  Col,
  Input,
  Row,
  Form,
  Button,
  Radio,
  Typography,
  Card,
  Checkbox,
  Select,
} from "antd";
import FloatLabel from "src/components/Form/FloatLabel";
import { searchSubCourseQuestionData } from "src/state/SubCourseQuestion/subCourseQuestion.action";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { useAppSelector } from "src/state/app.hooks";
import { questionSelector } from "src/state/SubCourseQuestion/subCourseQuestion.reducer";
import { IQuestioneDetails } from "src/services/subCourseQuestion/subCourseQuestion.model";
import { showTooltip } from "src/utils/helperFunction";
import { IQuestionBankAddProps } from "./Add.model";
import { Option } from "antd/lib/mentions";
import {
  QuestionBankType,
  QuestionType,
  TopicType,
} from "src/utils/constants/constant";
import { useWatch } from "antd/lib/form/Form";

const QuestionBankAdd = (props: IQuestionBankAddProps) => {
  const { data, onSubmit, myRef } = props;
  const [form] = Form.useForm();
  const { Text } = Typography;
  const { subcourse_id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const questionType = useWatch("question_bank_type", form);
  const subCourseQuestionState = useAppSelector(questionSelector);
  const rules = {
    name: [{ required: true, message: "Please Enter Name" }],
    exam_time_hours: [
      { required: true, message: "Please Enter Exam Duration" },
      {
        validator: (_: any, value: number) =>
          Number(value) <= 10
            ? Promise.resolve()
            : Promise.reject("Maximum allowed value for Exam Duration is 10."),
      },
    ],
    question_bank_type: [
      { required: true, message: "Please Select Question Bank Type" },
    ],
  };
  const [questionData, setQuestionData] = useState<IQuestioneDetails[]>();
  const [selectedQuestions, setSelectedQuestions] = useState<Number[]>([]);
  const maxLength = 76;

  useEffect(() => {
    dispatch(
      searchSubCourseQuestionData({
        noLimit: true,
        orderBy: "id",
        order: "ASC",
        subcourse_id: subcourse_id,
      })
    );
  }, [dispatch, subcourse_id]);

  useEffect(() => {
    setQuestionData(subCourseQuestionState.SubCourseQuestionData.data?.rows);
  }, [subCourseQuestionState.SubCourseQuestionData.data?.rows]);

  useEffect(() => {
    if (data && data.question_bank_questions) {
      setSelectedQuestions(
        data.question_bank_questions.map((q) => q.question_id)
      );
    }
  }, [data]);

  const handleCheckboxChange = (questionId: number, checked: boolean) => {
    setSelectedQuestions((prevSelectedQuestions) =>
      checked
        ? [...prevSelectedQuestions, questionId]
        : prevSelectedQuestions.filter((id) => id !== questionId)
    );
  };

  const handleAllSelect = (checked: boolean) => {
    if (checked) {
      const filteredQuestions =
        questionData
          ?.filter((question) => {
            if (questionType === TopicType.EXAM_THEORY) {
              return question.topic_type === TopicType.EXAM_THEORY;
            } else if (questionType === TopicType.EXAM_PRACTICAL) {
              return question.topic_type === TopicType.EXAM_PRACTICAL;
            }
            return false;
          })
          .map((question) => question.id) || [];

      setSelectedQuestions(filteredQuestions);
    } else {
      setSelectedQuestions([]);
    }
  };

  return (
    <>
      <Form
        form={form}
        className="drawer-form question-form"
        name="myForm"
        initialValues={data}
        onFinish={(values) => {
          const questionBankQuestions = selectedQuestions.map((question_id) => {
            const existingQuestion = data?.question_bank_questions?.find(
              (q) => q.question_id === question_id
            );
            return existingQuestion
              ? { id: existingQuestion.id, question_id }
              : { question_id };
          });
          const finalData = {
            ...values,
            questionBankQuestions,
          };
          onSubmit(finalData);
        }}
        layout="vertical"
        autoComplete="off"
        requiredMark={true}
      >
        <Row gutter={10}>
          <Col span={12}>
            <FloatLabel
              label="Question Bank Name"
              placeholder="Enter Question Bank Name"
              name="name"
              required
            >
              <Form.Item name="name" rules={rules.name}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={12}>
            <FloatLabel
              label="Exam Duration"
              placeholder="Enter Exam Duration"
              name="exam_time_hours"
              required
            >
              <Form.Item name="exam_time_hours" rules={rules.exam_time_hours}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Question Bank Type"
              placeholder="Select Question Bank Type"
              required
              name="question_bank_type"
            >
              <Form.Item
                name="question_bank_type"
                rules={rules.question_bank_type}
              >
                <Select
                  size="large"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.children?.toString() || "")
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                  onChange={(value) => {
                    setSelectedQuestions([]);
                  }}
                >
                  <Option key="1" value={QuestionBankType.EXAM_PRACTICAL}>
                    EXAM PRACTICAL
                  </Option>
                  <Option key="2" value={QuestionBankType.EXAM_THEORY}>
                    EXAM THEORY
                  </Option>
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          {data && (
            <Col span={24}>
              <Text strong style={{ display: "block" }}>
                Status
              </Text>
              <Form.Item name="status">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          )}
        </Row>
        <Card className="faculty-dashboard-card create-question-bank gx-mt-2 gx-mx-2 create-question-bank">
          <div className="card-body">
            <table>
              <thead>
                <tr>
                  <th
                    className="sticky-top gx-text-left"
                    style={{ width: "70%" }}
                  >
                    <Checkbox
                      style={{ fontWeight: 400 }}
                      className="questionBankQuestions gx-mr-2"
                      onChange={(e) => handleAllSelect(e.target.checked)}
                      checked={
                        selectedQuestions.length === questionData?.length
                      }
                    ></Checkbox>
                    Question List
                  </th>
                  <th
                    className="sticky-top gx-text-left"
                    style={{ width: "200px" }}
                  >
                    Severity
                  </th>
                  <th
                    className="sticky-top gx-text-left"
                    style={{ width: "200px" }}
                  >
                    Marks
                  </th>
                </tr>
              </thead>
              <tbody>
                {questionData &&
                  questionData
                    .filter((question) => {
                      if (questionType === TopicType.EXAM_THEORY) {
                        return question.topic_type === TopicType.EXAM_THEORY;
                      } else if (questionType === TopicType.EXAM_PRACTICAL) {
                        return question.topic_type === TopicType.EXAM_PRACTICAL;
                      }
                    })
                    .map((question) => (
                      <tr key={question.id}>
                        <td style={{ width: "70%", wordWrap: "break-word" }}>
                          <Checkbox
                            style={{ fontWeight: 400 }}
                            className="questionBankQuestions"
                            checked={selectedQuestions.includes(question.id)}
                            onChange={(e) =>
                              handleCheckboxChange(
                                question.id,
                                e.target.checked
                              )
                            }
                          >
                            {showTooltip(question.question, maxLength)}{" "}
                            {`(${question.question_type})`} <br />
                            <small>
                              {question.tags?.map((data, index) => (
                                <span key={data.id}>
                                  {data.name}
                                  {question.tags &&
                                    index !== question.tags.length - 1 &&
                                    ", "}
                                </span>
                              ))}
                            </small>
                          </Checkbox>
                        </td>
                        <td style={{ width: "200px" }}>
                          <div className="tag-name">{question.severity}</div>
                        </td>
                        <td style={{ width: "200px" }}>
                          <div className="tag-name">{question.marks}</div>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </Card>

        <Form.Item className="modal-btn-grp" style={{ display: "none" }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            ref={myRef}
          >
            {data ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default QuestionBankAdd;
