import { config } from "src/utils";
import request from "../../lib/axios/request";
import { IApiResponse } from "../../utils/models/common";
import axios from "axios";
import { ISystemTrackerData, ISystemTrackerDetails } from "./systemTracker.model";

class SyatemTrackerService {
  ENDPOINT = config.baseApiSystemTracker+ "/get-pc-details";

  public getSystemTrackerDetails = async (
    data: ISystemTrackerDetails
  ): Promise<IApiResponse<ISystemTrackerData[]>> => {
    const url = `${this.ENDPOINT}`;
    try {
      const response = await axios.request({
        url,
        method: 'GET',
        params: data,
        headers: {
          Authorization: `${process.env.REACT_APP_SYATEN_TRACKER_TOKEN}`,
          'Content-Type': 'application/json', 
        },
      });
      
      if (response.data) {
        return response.data;
      } else {
          throw new Error('No data found');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw error;
      } else {
        throw new Error('Unexpected error occurred');
      }
    }
  };
}

export default new SyatemTrackerService();