import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import ContainerHeader from "src/components/ContainerHeader";
import {
  FilterFilled,
  FileTextOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import TableComponent from "src/components/DataTable";
import { ColumnsType } from "antd/lib/table";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FloatLabel from "src/components/Form/FloatLabel";
import {
  bufferURLEncode,
  facultyDataByBranchId,
  showTooltip,
  trimObject,
} from "src/utils/helperFunction";
import { useAppSelector } from "src/state/app.hooks";
import { userSelector } from "src/state/users/user.reducer";
import { TopicType, ZoneType } from "src/utils/constants/constant";
import { branchSelector } from "src/state/branch/branch.reducer";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { searchBranchData } from "src/state/branch/branch.action";
import { searchZoneData } from "src/state/zone/zone.action";
import { AppDispatch } from "src/state/app.model";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  clearRemoveMessage,
  reportSelector,
} from "src/state/report/report.reducer";
import {
  examMarkReportCsvEmail,
  searchStudentExamMarks,
} from "src/state/report/report.action";
import StudentProfileModel from "src/components/StudentProfileModel";
import {
  IStudentExamMarks,
  IStudentExamMarksDetails,
  ISubTopicData,
} from "src/services/report/report.model";
import { searchUserData } from "src/state/users/user.action";
import { IUserData } from "src/services/user/user.model";
import { Can } from "src/ability/can";
import { Common } from "../../utils/constants/constant";
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ExamMarks = () => {
  const dispatch = useDispatch<AppDispatch>();
  const reportState = useAppSelector(reportSelector);
  const [data, setData] = useState<IStudentExamMarks>();
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const branchState = useAppSelector(branchSelector);
  const zoneState = useAppSelector(zoneSelector);
  const userState = useAppSelector(userSelector);
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;
  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );
  const [formValues, setFormValues] = useState({});
  const zoneId = Form.useWatch("zone_ids", form);
  const branchId = Form.useWatch("branch_id", form);
  const [selectedGradeStudentList, setSelectedGradeStudentList] =
    useState<IStudentExamMarksDetails>();
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [dynamicFaculty, setDynamicFaculty] = useState<IUserData[]>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );

    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        type: ZoneType.PUBLIC,
        parent_id: currentUserZone?.id,
      })
    );

    dispatch(
      searchUserData({ noLimit: true, orderBy: "first_name", order: "ASC" })
    );
  }, []);

  useEffect(() => {
    if (reportState.examMarkCSVData.message) {
      if (reportState.examMarkCSVData.hasErrors) {
        message.error(reportState.examMarkCSVData.message);
      } else {
        message.success(reportState.examMarkCSVData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [reportState.examMarkCSVData.message]);

  const dataConvertFromSearchParm = () => {
    let data = {};
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;

      if (value !== "") {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    return data;
  };

  useEffect(() => {
    const data = {
      ...setFormValues,
      ...dataConvertFromSearchParm(),
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value !== "") {
        if (key === "branch_id") {
          Object.assign(data, {
            ["branch_id"]: value.split(","),
          });
        } else if (key === "zone_ids") {
          Object.assign(data, {
            zone_ids: value.split(",").map((zone) => Number(zone)),
          });
        } else {
          Object.assign(data, {
            [key]: value,
          });
        }
      }
    }
    const urlData = Object.fromEntries(new URLSearchParams(searchParams));
    if (urlData && urlData?.start_date && urlData?.end_date) {
      Object.assign(data, {
        ["date"]: [moment(urlData?.start_date), moment(urlData?.end_date)],
      });
    }

    setFormValues(data);
  }, [searchParams]);

  useEffect(() => {
    dispatch(searchStudentExamMarks(dataConvertFromSearchParm())).then(() => {
      setLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    setData(reportState.studentExamMarks.data);
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [reportState.studentExamMarks.data]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  useEffect(() => {
    if (!searchParams.get("gr_id")) {
      form.setFieldValue("gr_id", undefined);
    }
  }, [window.location.search]);

  const onFinish = (values: { [key: string]: string }) => {
    let data = Object.fromEntries(
      new URLSearchParams(trimObject(searchParams))
    );
    if (values.date) {
      let date = {
        start_date: moment(values.date[0]).format("YYYY-MM-DD"),
        end_date: moment(values.date[1]).format("YYYY-MM-DD"),
      };
      data = date;
    }

    values = { ...data, ...values };
    const { date, ...rest } = values;
    const newData = Object.assign(rest);
    Object.keys(newData).forEach(
      (key) =>
        (newData[key] === undefined ||
          newData[key] === null ||
          newData[key].length <= 0) &&
        delete newData[key]
    );

    const queryString = Object.entries(trimObject(newData))
      .filter(([key, newData]) => newData && newData !== "")
      .map(([key, newData]) => key + "=" + newData)
      .join("&");

    setSearchParams(queryString.trim());
    setFilterModalOpen(false);
  };

  const onReset = () => {
    setSearchParams({});
    setFormValues(" ");
    form.resetFields();
    setCount(0);
  };

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const getFacultyData = (branch_id: number[], zoneId?: number[]) => {
    const branchData =
      branchState.branchesData.data.rows.filter((branch) =>
        zoneId && zoneId.length > 0
          ? zoneId?.includes(branch.zone?.parent_id[0].id)
          : branch_id.includes(branch.id)
      ) || [];

    return facultyDataByBranchId(
      branchData.map((branch) => branch.id),
      userState.usersData.data,
      branchState.branchesData.data
    );
  };

  useEffect(() => {
    if (branchState.branchesData.data?.rows && userState.usersData.data?.rows) {
      const currentZoneFaculty =
        branchState.branchesData.data.rows.length > 0
          ? getFacultyData(
              branchState.branchesData.data.rows
                .filter((branch) =>
                  branchId && branchId.length > 0
                    ? branchId.includes(branch.id.toString())
                    : branch
                )
                .map((branch) => branch.id),
              zoneId !== undefined && !(branchId && branchId.length > 0)
                ? [Number(zoneId)]
                : []
            )
          : [];
      setDynamicFaculty(currentZoneFaculty);
    }
  }, [
    zoneId,
    branchId,
    branchState.branchesData.data.rows,
    userState.usersData.data.rows,
  ]);

  const allBranchIds = branchState.branchesData.data.rows.map(
    (branch) => branch.id
  );
  const zoneChange = (value: string) => {
    const facultyDataByBranch: IUserData[] = getFacultyData(
      branchState.branchesData.data.rows.map((branch) => branch.id),
      [Number(value)]
    );
    setDynamicFaculty(facultyDataByBranch);
    form.setFieldValue("branch_id", undefined);
    form.setFieldValue("user_id", undefined);
  };
  const branchChange = (value: number[]) => {
    const facultyDataByBranch: IUserData[] = getFacultyData(
      value && value.length > 0 ? value : allBranchIds,
      []
    );
    setDynamicFaculty(facultyDataByBranch);
    form.setFieldValue("user_id", undefined);
  };

  const info = (value: string) => {
    Modal.info({
      title: "Sub Topic",
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };
  const backURL = bufferURLEncode(`${location.pathname}${location.search}`);

  const columns: ColumnsType<IStudentExamMarksDetails> = [
    {
      title: "No.",
      dataIndex: "id",
      sorter: false,
      align: "center",
      width: "5%",
      render: (text, record, index) => (
        <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "GR ID",
      className: "gr_id",
      render: (record) => {
        return (
          <div
            className="gx-text-danger"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedGradeStudentList(record);
              setOpenModel(true);
            }}
          >
            {record.gr_id}
          </div>
        );
      },
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      className: "student_name",
    },
    {
      title: "Branch Name",
      dataIndex: "branch_code",
      className: "branch_code",
    },
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      render: (_, record) => {
        const maxLength = 50;
        const handleClick = () => {
          if (record?.batch_id) {
            navigate(`/batch/${record.batch_id}/faculty?r=${backURL}`);
          }
        };
        return (
          <>
            <div
              onClick={handleClick}
              style={{ cursor: "pointer" }}
              className="gx-text-danger"
            >
              <span>{showTooltip(record.batch_name, maxLength)}</span>
            </div>
            <small className="gx-d-block faculty-name">
              {record?.faculty_name}
            </small>
          </>
        );
      },
    },
    {
      title: "SubCourse Name",
      dataIndex: "subcourse_name",
      className: "subcourse_name",
    },
    {
      title: "Topic/Subtopic Name",
      dataIndex: "",
      className: "",
      render: (record) => {
        const descriptions = record?.sub_topic
          ?.map((x: ISubTopicData) => x?.description)
          .join("");
        const isLong = descriptions && descriptions.length > 60;
        return (
          <>
            <span>{record?.topic_name}</span>
            <br />
            <div className="view-remarks">
              <small className="gx-mr-1 gx-mb-1 subtopic-text faculty-name">
                <span dangerouslySetInnerHTML={{ __html: descriptions }} />
              </small>
              {isLong && (
                <Button type="primary" onClick={() => info(descriptions)}>
                  More
                </Button>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Type",
      align: "center",
      dataIndex: "type",
      className: "type",
    },
    {
      title: "Marks",
      align: "center",
      render: (record) => <>{`${record.marks} / ${record.total_marks}`}</>,
    },
  ];

  const examMarkReportCsvMail = () => {
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    dispatch(examMarkReportCsvEmail(data));
  };

  return (
    <div>
      <Skeleton active avatar loading={loading}>
        <Row
          align="middle"
          justify="space-between"
          gutter={24}
          className="mb-20"
        >
          <Col xxl={12}>
            <ContainerHeader title="Exam Marks" />
          </Col>
          <Col xxl={12} className="text-align-right">
            <Can
              I={Common.Actions.CAN_VIEW}
              a={Common.Modules.REPORT.EXAM_MARK_CSV}
            >
              <Button
                icon={<FileTextOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title:
                      "Are you sure want to download telly report csv file?",
                    okText: "Yes",
                    cancelText: "No",
                    icon: <DownloadOutlined />,
                    onOk() {
                      examMarkReportCsvMail();
                    },
                  });
                }}
              >
                CSV
              </Button>
            </Can>
            <Button
              icon={<FilterFilled />}
              onClick={() => setFilterModalOpen(true)}
            >
              Filter
              <span>
                <Badge count={count}></Badge>
              </span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="filter" style={{ height: "auto" }}>
              <Drawer
                className="filter-drawer"
                title="Exam Marks Filter"
                width="1000"
                visible={filterModalOpen}
                onClose={() => {
                  setFilterModalOpen(false);
                }}
                footer={[
                  <div className="gx-d-flex gx-justify-content-center">
                    <Button
                      className="cancel-filter gx-mr-0"
                      key="back"
                      onClick={onCancel}
                    >
                      <span className="gx-d-none gx-d-sm-block">Cancel</span>
                      <i className="fa fa-close gx-d-sm-none"></i>
                    </Button>
                    <Button
                      className="btn-apply-filter gx-mx-2"
                      key="submit"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      form="myForm"
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className="reset-filter"
                      type="default"
                      htmlType="reset"
                      form="myForm"
                    >
                      <span className="gx-d-none gx-d-sm-block">Reset</span>
                      <i className="fa fa-refresh gx-d-sm-none"></i>
                    </Button>
                  </div>,
                ]}
              >
                <Form
                  id="myForm"
                  onFinish={onFinish}
                  form={form}
                  onReset={onReset}
                  initialValues={formValues}
                >
                  <Row gutter={24}>
                    <Col xs={24}>
                      <FloatLabel
                        label="GR ID"
                        placeholder="Enter GR ID"
                        name="gr_id"
                      >
                        <Form.Item name="gr_id">
                          <InputNumber maxLength={9} size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    {currentUserZone?.type !== ZoneType.PRIVATE &&
                      zoneState.zonesData.data.rows.length > 0 && (
                        <Col xs={24}>
                          <FloatLabel
                            label="Zone"
                            placeholder="Select Zone"
                            name="zone_ids"
                          >
                            <Form.Item name="zone_ids">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                showArrow
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.children?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase().trim())
                                }
                                onChange={zoneChange}
                              >
                                {zoneState.zonesData.data.rows
                                  .filter(
                                    (zone) =>
                                      zone?.id === currentUserZone?.id ||
                                      zone?.parent_id?.id ===
                                        currentUserZone?.id
                                  )
                                  .filter((z) => z.status === true)
                                  .map((zone) => (
                                    <Option value={zone.id}>{zone.name}</Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      )}
                    {currentUserZone?.type !== ZoneType.PRIVATE && (
                      <Col xs={24}>
                        <FloatLabel
                          label="Branch Code"
                          placeholder="Select Branch Code"
                          name="branch_id"
                        >
                          <Form.Item name="branch_id">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              mode="multiple"
                              showArrow
                              allowClear
                              showSearch
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                              onChange={branchChange}
                            >
                              {branchState.branchesData.data.rows
                                .filter((item) =>
                                  zoneId
                                    ? Number(item.zone?.parent_id[0].id) ===
                                      Number(zoneId)
                                    : item
                                )
                                .map((branch) => {
                                  return (
                                    <Option value={branch?.id.toString()}>
                                      {branch?.code}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                    )}
                    <Col xs={24}>
                      <FloatLabel
                        placeholder="Select Faculty"
                        label="Select Faculty"
                        name="user_id"
                      >
                        <Form.Item name="user_id">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            allowClear
                            className="dash_input"
                            style={{
                              width: "100%",
                            }}
                            showSearch
                            showArrow
                            size="large"
                            filterOption={(value, option) =>
                              (option?.children?.toString() || "")
                                .toLowerCase()
                                .includes(value.toLowerCase().trim())
                            }
                          >
                            {dynamicFaculty &&
                              dynamicFaculty.map((u) => (
                                <Option value={u.id.toString()}>
                                  {u.first_name + " " + u.last_name}
                                </Option>
                              ))}
                            <></>
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Batch Name"
                        placeholder="Enter Batch Name"
                        name="batch_name"
                      >
                        <Form.Item name="batch_name">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Exam Type"
                        placeholder="Select Exam Type"
                        name="type"
                      >
                        <Form.Item name="type">
                          <Select
                            getPopupContainer={(trigger) => trigger.parentNode}
                            size="large"
                            allowClear
                          >
                            <Option value={TopicType.PROJECT}>PROJECT</Option>
                            <Option value={TopicType.VIVA}>VIVA</Option>
                            <Option value={TopicType.EXAM_PRACTICAL}>
                              EXAM PRACTICAL
                            </Option>
                            <Option value={TopicType.EXAM_THEORY}>
                              EXAM THEORY
                            </Option>
                            <Option value={TopicType.ASSIGNMENT}>
                              ASSIGNMENT
                            </Option>
                          </Select>
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <FloatLabel
                        label="Subcourse"
                        placeholder="Select Subcourse"
                        name="subcourse_name"
                      >
                        <Form.Item name="subcourse_name">
                          <Input size="large" />
                        </Form.Item>
                      </FloatLabel>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name="date">
                        <RangePicker
                          style={{ width: "100%" }}
                          getPopupContainer={(trigger) => trigger}
                          size="large"
                          name="date"
                          format={dateFormat}
                          ranges={{
                            Today: [moment(), moment()],
                            Week: [
                              moment().startOf("week"),
                              moment().endOf("week"),
                            ],
                            "This Month": [
                              moment().startOf("month"),
                              moment().endOf("month"),
                            ],
                            "This Year": [
                              moment().startOf("year"),
                              moment().endOf("year"),
                            ],
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </div>
            <Card className="rnw-card zone gx-mb-0">
              <TableComponent
                columns={columns}
                dataSource={data?.rows || []}
                loading={false}
                meta={data?.meta}
              />
            </Card>
          </Col>
        </Row>
        {openModel && (
          <StudentProfileModel
            openModel={openModel}
            setOpenModel={(data) => setOpenModel(data)}
            modelData={{
              ...selectedGradeStudentList,
              father_name: selectedGradeStudentList?.father_name,
              profilePhoto: selectedGradeStudentList?.photos,
              studentName: selectedGradeStudentList?.student_name,
              batch_name: selectedGradeStudentList?.batch_name,
              admission_id: selectedGradeStudentList?.admission_id,
            }}
          />
        )}
      </Skeleton>
    </div>
  );
};

export default ExamMarks;
