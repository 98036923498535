import { useEffect, useState } from "react";
import { FilterFilled, FileTextOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import {
  getDegradeAndCancelRemarkData,
  sendDegradeCancellationCsvFile,
} from "src/state/report/report.action";
import {
  Button,
  Card,
  Col,
  Popover,
  Row,
  Skeleton,
  Badge,
  Form,
  Drawer,
  Select,
  DatePicker,
  Input,
  Typography,
  Table,
  Rate,
  Modal,
  message,
} from "antd";

import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import ContainerHeader from "src/components/ContainerHeader";
import TableComponent from "src/components/DataTable";
import type { ColumnsType } from "antd/es/table";
import { useAppSelector } from "src/state/app.hooks";
import {
  clearRemoveMessage,
  reportSelector,
} from "src/state/report/report.reducer";
import {
  GetSortOrder,
  dateFormate,
  trimObject,
} from "src/utils/helperFunction";
import { IDCAdmissionSubcourses } from "src/services/report/report.model";
import { searchBranchData } from "src/state/branch/branch.action";
import FloatLabel from "src/components/Form/FloatLabel";
import moment from "moment";
import { branchSelector } from "src/state/branch/branch.reducer";
import { searchSubCourseData } from "src/state/subCourse/subCourse.action";
import { subcourseSelector } from "src/state/subCourse/subCourse.reducer";
import { IDCStudentRemarks } from "./Degrade&CancellStudentRemarks.model";
import DrawerComponent from "src/components/Drawer";
import { IRemarkDetails } from "src/services/admission/admission.model";
import { zoneSelector } from "src/state/zone/zone.reducer";
import { searchZoneData } from "src/state/zone/zone.action";
import { userSelector } from "src/state/users/user.reducer";
import { AdmisionStatusType, ZoneType } from "src/utils/constants/constant";
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

function DegradeAndCancellRemarkReport() {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const reportState = useAppSelector(reportSelector);
  const branchState = useAppSelector(branchSelector);
  const zoneState = useAppSelector(zoneSelector);
  const subCourseSate = useAppSelector(subcourseSelector);
  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [remarksModalOpen, setRemarksModalOpen] = useState(false);
  const [currentAdmissionRemarkData, setCurrentAdmissionRemarkData] = useState(
    []
  );
  const { userData } = useAppSelector(userSelector);
  const storageID: string | null = localStorage.getItem("myStorageID");
  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  const rules = {
    gr_id: [
      {
        pattern: /^[0-9]+$/,
        message: "Only numbers are allowed",
      },
    ],
  };
  const zoneData = Form.useWatch("zone", form);
  const branchData =
    branchState.branchesData.data.rows
      .filter((branch) =>
        zoneData ? zoneData == branch.zone?.parent_id[0].id : branch
      )
      .filter((branch) => branch.status === true) || [];

  const [page, setPage] = useState(
    searchParams.get("skip") && searchParams.get("take")
      ? Number(searchParams.get("skip")) / Number(searchParams.get("take")) + 1
      : 1
  );

  const dataConvertFromSearchParm = () => {
    let data = {};
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value == "") {
        setSearchParams("");
        data = "";
      }
      if (key === "admission_status") {
        Object.assign(data, {
          ["admission_status"]: value.split(","),
        });
      } else {
        Object.assign(data, {
          [key]: value,
        });
      }
    }
    return data;
  };

  useEffect(() => {
    const data = {
      ...setFormValues,
      ...dataConvertFromSearchParm(),
    };
    for (const entry of Array.from(searchParams.entries())) {
      const [key, value] = entry;
      if (value !== "") {
        if (key === "branch_id") {
          Object.assign(data, {
            branch_id: value.split(",").map((branch) => Number(branch)),
          });
        } else if (key === "subcourse_id") {
          Object.assign(data, {
            subcourse_id: value
              .split(",")
              .map((subcourse_id) => Number(subcourse_id)),
          });
        } else if (key === "admission_status") {
          if (value !== "") {
            Object.assign(data, { admission_status: value.split(",") });
          }
        } else {
          Object.assign(data, {
            [key]: value,
          });
        }
      }
    }
    const urlData = Object.fromEntries(new URLSearchParams(searchParams));
    if (urlData && urlData?.start_date && urlData?.end_date) {
      Object.assign(data, {
        ["date"]: [moment(urlData?.start_date), moment(urlData?.end_date)],
      });
    }
    setFormValues(data);
  }, [searchParams]);

  useEffect(() => {
    dispatch(getDegradeAndCancelRemarkData(dataConvertFromSearchParm())).then(
      () => {
        setLoading(false);
      }
    );
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get("skip") && searchParams.get("take")) {
      setPage(
        searchParams.get("skip") && searchParams.get("take")
          ? Number(searchParams.get("skip")) /
              Number(searchParams.get("take")) +
              1
          : 1
      );
    }
  }, [reportState.degradeAndCancelRemarkData.data]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      form.resetFields();
    }
  }, [formValues]);

  useEffect(() => {
    if (reportState.degradeAndCancelRemarkCvData.message) {
      if (reportState.degradeAndCancelRemarkCvData.hasErrors) {
        message.error(reportState.degradeAndCancelRemarkCvData.message);
      } else {
        message.success(reportState.degradeAndCancelRemarkCvData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [reportState.degradeAndCancelRemarkCvData.message]);

  const onFinish = (values: IDCStudentRemarks) => {
    let data = Object.fromEntries(
      new URLSearchParams(trimObject(searchParams))
    );
    if (values.date) {
      let date = {
        start_date: moment(values.date[0]).format("YYYY-MM-DD"),
        end_date: moment(values.date[1]).format("YYYY-MM-DD"),
      };
      data = date;
    }

    values = { ...data, ...values };
    const { date, ...rest } = values;
    const newData = Object.assign(rest);
    Object.keys(newData).forEach(
      (key) =>
        (newData[key] === undefined || newData[key].length <= 0) &&
        delete newData[key]
    );

    const queryString = Object.entries(trimObject(newData))
      .filter(([key, newData]) => newData && newData !== "")
      .map(([key, newData]) => key + "=" + newData)
      .join("&");

    setSearchParams(queryString.trim());
    setFilterModalOpen(false);
  };

  useEffect(() => {
    dispatch(
      searchBranchData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        isZoneOnly: true,
      })
    );

    dispatch(
      searchSubCourseData({ noLimit: true, orderBy: "name", order: "ASC" })
    );

    dispatch(
      searchZoneData({
        noLimit: true,
        orderBy: "name",
        order: "ASC",
        type: ZoneType.PUBLIC,
        parent_id: currentUserZone?.id,
      })
    );
  }, []);

  const onReset = () => {
    setSearchParams({});
    setFormValues(" ");
    form.resetFields();
    setCount(0);
  };

  useEffect(() => {
    let sum = 0;
    const data = Object.fromEntries(new URLSearchParams(searchParams));
    for (const [key, value] of Object.entries(data)) {
      if (
        key !== "orderBy" &&
        key !== "order" &&
        key !== "skip" &&
        key !== "take" &&
        data[key] !== ""
      ) {
        sum += 1;
      }
    }
    setCount(sum);
  }, [window.location.search]);

  const onCancel = () => {
    setFilterModalOpen(false);
  };

  const columns: ColumnsType = [
    {
      title: "No.",
      className: "no",
      width: "5%",
      align: "center",
      render: (text, record, index) => (
        <>{(page - 1) * Number(searchParams.get("take")) + index + 1}</>
      ),
    },
    {
      title: "GR ID",
      className: "gr_id",
      dataIndex: "gr_id",
      key: "gr_id",
      width: "30%",
    },
    {
      title: "Branch",
      className: "branch",
      dataIndex: ["branch", "code"],
      key: "code",
      width: "5%",
    },
    {
      title: "Package Name",
      className: "package",
      dataIndex: ["package", "name"],
    },
    {
      title: "Sub Course",
      className: "course",
      dataIndex: ["admission_subcourses"],
      width: "30%",
      render: (record) => {
        return (
          <>
            <div className="roles-details gx-d-flex ">
              {record.length > 1 ? (
                <>
                  <p className="gx-mb-1">
                    {record.length > 0 ? record[0].subcourse.name : ""}
                  </p>
                  <div className="gx-ml-2">
                    <Popover
                      content={
                        <p className="gx-mb-1 role-modal-details ">
                          {record?.map((sub_course: IDCAdmissionSubcourses) => {
                            return (
                              <p className="gx-mb-1">
                                {sub_course.subcourse.name}
                              </p>
                            );
                          })}
                        </p>
                      }
                      placement="bottomRight"
                      title="Subcourses"
                      trigger="click"
                      getPopupContainer={(parent) =>
                        parent.parentElement as HTMLElement
                      }
                    >
                      <Button type="primary">More</Button>
                    </Popover>
                  </div>
                </>
              ) : (
                <p className="gx-mb-1">
                  {record.length > 0 ? record[0].subcourse.name : ""}
                </p>
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "No. of Lectures",
      className: "no_of_lectures",
      render: (record) => {
        return record.batchStudentAttendances.length ?? 0;
      },
    },
    {
      title: "Status",
      className: "status",
      dataIndex: "status",
      key: "status",
      width: "30%",
    },
    {
      title: "Date",
      className: "date",
      dataIndex: "start_date",
      key: "start_date",
      width: "30%",
      render: (record) => {
        return <>{dateFormate(record)}</>;
      },
    },
    {
      title: "Remarks",
      className: "remarks",
      key: "remarks",
      width: "30%",
      render: (record) => {
        return (
          <>
            <div className="action-icon">
              <div
                onClick={() => {
                  setRemarksModalOpen(true);
                  setCurrentAdmissionRemarkData(record.admission_remarks);
                }}
              >
                <Text
                  ellipsis={true}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  <EyeOutlined style={{ color: "#fff" }} />
                </Text>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const info = (value: string) => {
    Modal.info({
      title: "Remarks",
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };

  const remarkColumns: ColumnsType<IRemarkDetails> = [
    {
      title: "No.",
      dataIndex: "id",
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (remarks) => (
        <>
          <div className="view-remarks">
            <p className="gx-mr-1 note-text">
              <span dangerouslySetInnerHTML={{ __html: remarks }} />
            </p>
            <Button type="primary" onClick={() => info(remarks)}>
              More
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "Labels",
      dataIndex: "labels",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      render: (rating) => (
        <>
          <Rate disabled value={rating} />
          <span className="star-rating">
            <i className="fa fa-star gx-mr-2" style={{ color: "#fadb14" }}></i>
            {rating}
          </span>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "created_date",
      render: (record) => {
        return <>{dateFormate(record)}</>;
      },
    },
    {
      title: "Add By",
      dataIndex: "created_by",
    },
  ];

  const handleExpensesCsv = () => {
    dispatch(sendDegradeCancellationCsvFile(dataConvertFromSearchParm()));
  };

  return (
    <>
      <div className="rnw-main-content">
        <Skeleton loading={loading} active avatar>
          <Row
            align="middle"
            justify="space-between"
            gutter={24}
            className="mb-20"
          >
            <Col xxl={12}>
              <ContainerHeader title="Degrade & Cancellation Students Remarks" />
            </Col>

            <Col xl={8} className="text-align-right gx-mt-2 gx-mt-md-0 ">
              <div className="gx-d-flex gx-align-items-center gx-justify-content-end">
                <Button
                  icon={<FileTextOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title:
                        "Are you sure want to download Degrade&Cancellnation CSV file?",
                      okText: "Yes",
                      cancelText: "No",
                      icon: <DownloadOutlined />,
                      onOk() {
                        handleExpensesCsv();
                      },
                    });
                  }}
                >
                  CSV
                </Button>

                <Button
                  icon={<FilterFilled />}
                  onClick={() => setFilterModalOpen(true)}
                >
                  Filter
                  <span>
                    <Badge count={count}></Badge>
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="filter" style={{ height: "auto" }}>
                <Drawer
                  className="filter-drawer"
                  title="Degrade & Cancellation Students Remarks Report Filter"
                  width="1000"
                  visible={filterModalOpen}
                  onClose={() => {
                    setFilterModalOpen(false);
                  }}
                  footer={[
                    <div className="gx-d-flex gx-justify-content-center">
                      <Button
                        className="cancel-filter gx-mr-0"
                        key="back"
                        onClick={onCancel}
                      >
                        <span className="gx-d-none gx-d-sm-block">Cancel</span>
                        <i className="fa fa-close gx-d-sm-none"></i>
                      </Button>
                      <Button
                        className="btn-apply-filter gx-mx-2"
                        key="submit"
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        form="myForm"
                      >
                        Apply Filter
                      </Button>
                      <Button
                        className="reset-filter"
                        type="default"
                        htmlType="reset"
                        form="myForm"
                      >
                        <span className="gx-d-none gx-d-sm-block">Reset</span>
                        <i className="fa fa-refresh gx-d-sm-none"></i>
                      </Button>
                    </div>,
                  ]}
                >
                  <Form
                    id="myForm"
                    onReset={onReset}
                    onFinish={onFinish}
                    form={form}
                    initialValues={formValues}
                  >
                    <Row gutter={24}>
                      <Col xs={24}>
                        <FloatLabel
                          label="GR ID"
                          placeholder="Enter GR ID"
                          name="gr_id"
                        >
                          <Form.Item name="gr_id" rules={rules.gr_id}>
                            <Input size="large" maxLength={9} />
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      {currentUserZone?.type !== ZoneType.PRIVATE &&
                        zoneState.zonesData.data.rows.length > 0 && (
                          <Col xs={24}>
                            <FloatLabel
                              label="Zone"
                              placeholder="Select Zone"
                              name="zone"
                            >
                              <Form.Item name="zone">
                                <Select
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  showArrow
                                  allowClear
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.children?.toString() || "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase().trim())
                                  }
                                >
                                  {zoneState.zonesData.data.rows
                                    .filter(
                                      (zone) =>
                                        zone?.id === currentUserZone?.id ||
                                        zone?.parent_id?.id ===
                                          currentUserZone?.id
                                    )
                                    .filter((z) => z.status === true)
                                    .map((zone) => (
                                      <Option value={zone.id.toString()}>
                                        {zone.name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </FloatLabel>
                          </Col>
                        )}
                      {currentUserZone?.type !== ZoneType.PRIVATE && (
                        <Col xs={24}>
                          <FloatLabel
                            label="Branch Code"
                            placeholder="Select Branch Code"
                            name="branch_id"
                          >
                            <Form.Item name="branch_id">
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                mode="multiple"
                                showArrow
                                allowClear
                                showSearch
                                filterOption={(input, option) =>
                                  (option?.children?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase().trim())
                                }
                              >
                                {branchData.map((branch) => (
                                  <Option value={branch.id}>
                                    {branch.code}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      )}
                      <Col xs={24}>
                        <FloatLabel
                          label="Subcourse"
                          placeholder="Select Subcourse"
                          name="subcourse_id"
                        >
                          <Form.Item name="subcourse_id">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              mode="multiple"
                              showArrow
                              allowClear
                              showSearch
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                            >
                              {subCourseSate.searchData.data.rows.map(
                                (subCourse) => (
                                  <Option value={subCourse.id}>
                                    {subCourse.name}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>

                      <Col xs={24}>
                        <FloatLabel
                          label="Admission Status"
                          placeholder="Admission Status"
                          name="admission_status"
                        >
                          <Form.Item name="admission_status">
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              allowClear
                              showArrow
                              mode="multiple"
                              size="large"
                              filterOption={(input, option) =>
                                (option?.children?.toString() || "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase().trim())
                              }
                            >
                              <Option value={AdmisionStatusType.HOLD}>
                                Hold
                              </Option>
                              <Option value={AdmisionStatusType.CANCELLED}>
                                Cancelled
                              </Option>
                              <Option value={AdmisionStatusType.TERMINATED}>
                                Terminated
                              </Option>
                            </Select>
                          </Form.Item>
                        </FloatLabel>
                      </Col>
                      <Col xs={24}>
                        <Form.Item name="date">
                          <RangePicker
                            style={{ width: "100%" }}
                            getPopupContainer={(trigger) => trigger}
                            size="large"
                            name="date"
                            format={dateFormat}
                            ranges={{
                              Today: [moment(), moment()],
                              Week: [
                                moment().startOf("week"),
                                moment().endOf("week"),
                              ],
                              "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                              ],
                              "This Year": [
                                moment().startOf("year"),
                                moment().endOf("year"),
                              ],
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Drawer>
              </div>

              <Card className="rnw-card view-student">
                <TableComponent
                  columns={columns}
                  dataSource={(
                    reportState.degradeAndCancelRemarkData.data.rows || []
                  ).map((data, index) => ({
                    ...data,
                    key: index,
                  }))}
                  meta={reportState.degradeAndCancelRemarkData.data.meta}
                  loading={loading}
                />
              </Card>
            </Col>
          </Row>
          <DrawerComponent
            className="remark-admission overlapDrawer"
            title={"Remarks"}
            onClose={() => {
              setRemarksModalOpen(false);
            }}
            visible={remarksModalOpen}
          >
            <>
              <div className="remark-table">
                <Table
                  columns={remarkColumns}
                  pagination={false}
                  dataSource={
                    currentAdmissionRemarkData
                      .slice()
                      .sort(GetSortOrder("created_date", "DESC")) || []
                  }
                  loading={loading}
                />
              </div>
            </>
          </DrawerComponent>
        </Skeleton>
      </div>
    </>
  );
}

export default DegradeAndCancellRemarkReport;
