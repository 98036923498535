import request from "src/lib/axios/request";
import { IApiResponse } from "src/utils/models/common";
import {
  IAdmissionRecurringData,
  IGetStudentPtmRecordDetails,
  ISaveStudentPtmReportPayload,
  ISaveCompanyVisitPayload,
  IStudents,
  IUpdateCvData,
  IUpdateAdmissionRecuringData,
  PtmStudentReportEmailSendResponse,
  ISaveExpertSessionPayload,
  ISaveInterviewSessionPayload,
  IMissingDetailsParams,
  IAdmissionRecurringCreate,
} from "./student.model";
import { config } from "src/utils";

class StudentService {
  ENDPOINT = config.baseApiStudent + "/students";

  public searchStudentsData = async (
    data: IStudents | URLSearchParams | {}
  ): Promise<IApiResponse<IStudents[]>> => {
    const url = `${this.ENDPOINT}`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public studentSurveyBatchWiseData = async (
    data: string | URLSearchParams | {}
  ): Promise<IApiResponse<any[]>> => {
    const url = `${this.ENDPOINT}/student-survey-batch-wise`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getAdmissionRecurringData = async (
    data: IAdmissionRecurringData | URLSearchParams | {}
  ): Promise<IApiResponse<IAdmissionRecurringData[]>> => {
    const url = `${this.ENDPOINT}/admission-recurings`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public getAdmissionRecurringDataByAdmissionId = async (
    admission_id: number
  ): Promise<IApiResponse<IAdmissionRecurringData>> => {
    const url = `${this.ENDPOINT}/admission-recurring/${admission_id}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public createAdmissionRecurring = async (
    data: IAdmissionRecurringCreate
  ): Promise<IApiResponse<IAdmissionRecurringData>> => {
    const url = `${this.ENDPOINT}/admission-recurring`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public saveCompanyVisitData = async (
    data: ISaveCompanyVisitPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/cv-planing`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
  public saveExpertSessionData = async (
    data: ISaveExpertSessionPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/es-planning`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
  public updateExpertSessionData = async (
    data: ISaveExpertSessionPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/es-planning/${data.id}`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
  public getExpertSessionData = async (): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/es-planning`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
  public saveInterviewSessionData = async (
    data: ISaveInterviewSessionPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/interview-planning`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };
  public updateInterviewSessionData = async (
    data: ISaveInterviewSessionPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/interview-planning/${data.id}`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
  public getInterviewSessionData = async (): Promise<IApiResponse<string>> => {
    const url = `${config.baseApiStudent}/interview-planning`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public getCompanyVisitData = async (): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/cv-planing`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
  public getCompanyVisitById = async (
    id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/cv-planing/${id}`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
  public updateCompanyVisit = async (
    data: IUpdateCvData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/cv-planing/${data.id}`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
  public saveStudentPtmReport = async (
    data: ISaveStudentPtmReportPayload
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/student-ptm-report`;
    return request({ url, method: "POST", data: data }).then((res) => {
      return res.data;
    });
  };

  public getStudentPtmReportById = async (
    id: number
  ): Promise<IApiResponse<IGetStudentPtmRecordDetails>> => {
    const url = `${this.ENDPOINT}/${id}/student-ptm-reports`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };

  public getStudentPtmPerformanceById = async (
    id: number
  ): Promise<IApiResponse<IGetStudentPtmRecordDetails>> => {
    const url = `${this.ENDPOINT}/${id}/student-ptm-performance`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };

  public updateAdmissionRecuringsData = async (
    data: IUpdateAdmissionRecuringData
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${data.typeid}/admission-recurings-data-for-attendance`;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };

  public ptmStudentReportEmailSend = async (
    data: PtmStudentReportEmailSendResponse
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/ptm-student-report-email`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public missingAdmissionReportStaticsData = async (
    data: IMissingDetailsParams
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/admission-report-statics`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
}

export default new StudentService();
